
export const CornPackageLayerOptions = {
    'index': 5,
    'label': 'corn_package',
    'desc': 'corn_package_desc',
    'cardModifier': "program-card--insurance",
    'imgs': ['corn-1', 'corn-2'],
    'link': '/calculate-corteva-package/program/corn',
    'availability': true,
    'programs': [
        'chose_package',
        'chose_location',
        'chose_result',
    ]
};
export const SunflowerPackageLayerOptions = {
    'index': 6,
    'label': 'sunflower_package',
    'desc': 'sunflower_package_desc',
    'cardModifier': "program-card--insurance",
    'imgs': ['sunflower-1', 'sunflower-2'],
    'link': '/calculate-corteva-package/program/sunflower',
    'availability': true,
    'programs': [
        'chose_package',
        'chose_location',
        'chose_result',
    ]
};
export const WheatPackageLayerOptions = {
    'index': 7,
    'label': 'wheat_package',
    'desc': 'wheat_package_desc',
    'cardModifier': "program-card--insurance",
    'imgs': ['wheat-1', 'wheat-2'],
    'link': '/calculate-corteva-package/program/wheat',
    'availability': true,
    'programs': [
        'chose_package',
        'chose_location',
        'chose_result',
    ]
};
export const BarleyPackageLayerOptions = {
    'index': 8,
    'label': 'barley_package',
    'desc': 'barley_package_desc',
    'cardModifier': "program-card--insurance",
    'imgs': ['wheat-1', 'wheat-2'],
    'link': '/calculate-corteva-package/program/barley',
    'availability': true,
    'programs': [
        'chose_package',
        'chose_location',
        'chose_result',
    ]
};
