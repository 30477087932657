import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {withTranslation} from "react-i18next";
import InputMask from "react-input-mask";
import axios from "axios";

const Home = (props) => {
    const {t} = props;

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    const [
        {
            phone,
            valid,
            requestSent
        }, updateFormObj
    ] = useState({
        phone: null,
        valid: false,
        requestSent: false
    });

    const items = [
        {
            label: 'droughts',
            description: 'droughts_during',
            img: '/assets/img/icon-item-1.svg'
        },
        {
            label: 'high_temperatures',
            description: 'high_temperatures_on_summer',
            img: '/assets/img/icon-item-2.svg'
        },
        {
            label: 'mist',
            description: 'mist_description',
            img: '/assets/img/icon-item-5.svg'
        },
        {
            label: 'frosts',
            description: 'frost_after_sowing',
            img: '/assets/img/icon-item-3.svg'
        }
    ].map((e, i) => (
        <div className="problem-item item" key={i}>
            <div className="item-icon">
                <img src={e.img} alt=""/>
            </div>
            <h2 className="item-title">{t(e.label)}</h2>
            <div className="item-subtitle">{t(e.description)}
            </div>
        </div>
    ));

    const updatePhone = (e) => {
        updateFormObj({
            phone: e.target.value,
            valid: e.target.value.split('_').length === 1 && e.target.value.length !== 0
        });
    }

    const callRequest = async () => {
        const res = {phone, valid, requestSent};
        res['requestSent'] = true;
        updateFormObj(res);
        await axios({
            method: 'post',
            url: process.env.REACT_APP_API_HOST + "/insurance/request/phone",
            data: {
                phone: phone
            }
        })
            .then(function (response) {
            })
            .catch(function (response) {
                //handle error
            });
    }

    return (
        <div>
            <section className="hero">
                <div className="hero-content container">
                    <div className="hero-column-left column">
                        <h1 className="hero-title">MeteoControl – {t('keep_weather_under_control')}</h1>
                        <p className="hero-subtitle">{t('we_help_business')}</p>
                        <Link className="btn-default" id="main-calculate" replace={true}
                              to="/calculate-insurance">{t('calculate')}</Link>
                    </div>
                    <div className="hero-column-right column">
                        <img src="/assets/img/hero-img.svg" alt=""/>
                    </div>
                </div>
            </section>
            <section className="section problem container">
                <div className="section-title">
                    <h2 className="section-title">{t('main_weather_damage')}</h2>
                </div>
                <div className="problem-wrapper">
                    {items}
                </div>
            </section>
            <section className="insurance">
                <div className="insurance-content container">
                    <div className="insurance-column-left column">
                        <img src="/assets/img/insurance-img.svg" alt=""/>
                    </div>
                    <div className="insurance-column-right column">
                        <div className="insurance-text">
                            <h2 className="insurance-title">{t('parametric_insurance')}</h2>
                            <p className="insurance-desc">{t('parametric_insurance_description_1')}</p>
                            <p className="insurance-desc">{t('parametric_insurance_description_2')}</p>
                        </div>
                    </div>
                </div>
            </section>
            <div className="section advantages">
                <div className="section-title">
                    <h2>{t('advantages_of_parametric_insurance')}</h2>
                </div>
                <div className="advantage-wrapper container">
                    <div className="advantage-item">
                        <div className="advantage-item-img">
                            <img src="assets/img/data-storage.svg" alt=""/>
                        </div>
                        <div className="advantage-item-desc">{t('reliable_and_independent')}</div>
                    </div>
                    <div className="advantage-item">
                        <div className="advantage-item-img">
                            <img src="/assets/img/decision.svg" alt=""/>
                        </div>
                        <div className="advantage-item-desc">{t('ability_to_customize')}</div>
                    </div>
                    <div className="advantage-item">
                        <div className="advantage-item-img">
                            <img src="/assets/img/risk.svg" alt=""/>
                        </div>
                        <div className="advantage-item-desc">{t('cost_of_insurance')}</div>
                    </div>
                    <div className="advantage-item">
                        <div className="advantage-item-img">
                            <img src="/assets/img/disaster.svg" alt=""/>
                        </div>
                        <div className="advantage-item-desc">{t('not_necessary_to_receive')}</div>
                    </div>
                    <div className="advantage-item">
                        <div className="advantage-item-img">
                            <img src="/assets/img/team.svg" alt=""/>
                        </div>
                        <div className="advantage-item-desc">{t('ability_to_sign')}</div>
                    </div>
                </div>
            </div>
            <section className="section how-its-work">
                <div className="section-title">
                    <h2 className="section-title">{t('how_it_works')}</h2>
                </div>
                <div className="container">
                    <div className="how-its-work-wrapper">
                        <div className="how-its-work-item item">
                            <div className="item-icon">
                                <img src="/assets/img/financial.svg" alt=""/>
                            </div>
                            <div className="item-title">{t('calculate_insurance')}</div>
                            <div className="item-subtitle">{t('customize_parameters_to_your_own')}</div>
                        </div>
                        <div className="line">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <div className="how-its-work-item item">
                            <div className="item-icon">
                                <img src="/assets/img/contract.svg" alt=""/>
                            </div>
                            <div className="item-title">{t('sign_a_contract')}</div>
                            <div className="item-subtitle">{t('sign_a_contract_description')}
                            </div>
                        </div>
                        <div className="line">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <div className="how-its-work-item item">
                            <div className="item-icon">
                                <img src="/assets/img/debit-card.svg" alt=""/>
                            </div>
                            <div className="item-title">{t('get_paid')}</div>
                            <div className="item-subtitle">{t('receive_payment')}</div>
                        </div>
                    </div>
                    <div className="calc-wrapper">
                        <div className="calc">
                            <div className="calc-title">{t('calculate_the_price')}</div>
                            <Link className="btn-default" id="bottom-calculate" replace={true}
                                  to="/calculate-insurance">
                                {t('calculate_insurance_online')}
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section about-main" id="about-main">
                <div className="about-main-wrapper container">
                    <div className="about-main-column-left column">
                        <div className="about-main-text">
                            <h2 className="about-main-title">{t('home_section_t1')}</h2>
                            <p className="about-main-desc">{t('home_section_t2')}</p>
                            <p className="about-main-desc">{t('home_section_t3')}</p>
                            <a className="about-main-btn btn-default mt60" id="im-interested-calculate" target="_blank" rel="noopener noreferrer" href="https://www.agrifinance.io/">
                                {t('home_section_t4')}
                            </a>
                        </div>
                    </div>
                    <div className="about-main-column-right column">
                        <img src="/assets/img/agrifinance-img.png" alt=""/>
                    </div>
                </div>
            </section>
            <section className="section partners-main">
                <div className="section-title">
                    <h2>{t('home_section_t0')}</h2>
                </div>
                <div className="container">
                    <div className="partners-main-wrapper">
                        <div className="partners-main-item">
                            <div className="partners-main-item__img">
                                <img src="/assets/img/partner-main-1.png" alt=""/>
                            </div>
                            <div className="partners-main-item__title">
                                {t('home_section_t5')}
                            </div>
                        </div>
                        <div className="partners-main-item">
                            <div className="partners-main-item__img">
                                <img src="/assets/img/partner-main-2.png" alt=""/>
                            </div>
                            <div className="partners-main-item__title">
                                {t('home_section_t6')}
                            </div>
                        </div>
                        <div className="partners-main-item">
                            <div className="partners-main-item__img">
                                <img src="/assets/img/partner-main-3.png" alt=""/>
                            </div>
                            <div className="partners-main-item__title">
                                {t('home_section_t7')}
                            </div>
                        </div>
                        <div className="partners-main-item">
                            <div className="partners-mainr-item__img">
                                <img src="/assets/img/partner-main-4.png" alt=""/>
                            </div>
                            <div className="partners-main-item__title">
                                {t('home_section_t8')}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="recall container">
                <div className="recall-wrapper">
                    <h2 className="recall__title">{t('home_section_t9')}</h2>
                    <p className="recall__subtitle mt30">{t('home_section_t10')}</p>
                    {(requestSent ? (<div style={{lineHeight: '2.3em', fontSize: '21px', fontWeight: 700, color: '#5ab6a8'}}>
                        {t('home_section_t11')}
                    </div>) : (<div className="recall-form mt30">
                        <InputMask mask="+380 (99) 999-99-99"
                                   placeholder="+380 (99) 999-99-99"
                                   onChange={(e) => updatePhone(e)}
                                   className={"recall-form__input"}/>
                        <button className={"recall-form__btn btn-main btn " + (valid ? '' : 'disabled')} onClick={(e) => callRequest()}>{t('home_section_t12')}</button>
                    </div>))}
                </div>
                <img src="/assets/img/elem-1.svg" alt=""/>
            </div>
        </div>
    );
}

export default withTranslation()(Home);
