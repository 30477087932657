import React, {useEffect, useState} from 'react';
import Period from "./period";
import CoveringPicker, {PRICE_MULTIPLIER} from "./covering-picker";
import CoordinatesPicker from "./coordinates-picker";
import GroundLayer from "./ground-layer";
import {withTranslation} from "react-i18next";
import CalculatorHeader from "../shared/calculator_header";
import {getMaxValOfArrays, separateLineByTicks} from "../../utils/MathFunc";
import {checkIsPriceMultiplyRequired} from "../../utils/Conditions";
import Insurance from "../../services/insurance";
import {insuranceHOC} from "../../utils/insuranceHOC";

const insurance = Insurance.getInstance();

export const DroughtLayerOptions = {
    'index': 0,
    'condition': 'droughts',
    'label': 'drought',
    'imgs': ['calc-select-1', 'calc-select-4'],
    'cardModifier': "droughts",
    'layerIcons': [
        'radio-icon-1.svg',
        'radio-icon-2.svg',
        'radio-icon-3.svg',
        'radio-icon-4.svg',
    ],
    'layers': [
        '0-7',
        '7-28',
        '28-100',
        '100-289',
    ],
    'link': '/calculate-insurance/drought',
    'availability': true,
    'programs': [
        'chose_program',
        'chose_layer',
        'chose_period_of_insurance',
        'chose_location',
        'chose_cover',
    ]
};

const Drought = (props) => {
    const {t, i18n, insuranceParams} = props;
    const isPriceMultiplyRequired = checkIsPriceMultiplyRequired(i18n.language);
    const getColor = (value, trigger) => {
        return value <= trigger ? '#D96459' : '#7CCFC6';
    };
    const buttons = [
        {
            text: t('reliable_owner'),
            value: 0,
            percents: 20
        },
        {
            text: t('sufficient'),
            value: 1,
            percents: 15
        },
        {
            text: t('optimist'),
            value: 2,
            percents: 10
        }
    ];

    const tableData = {
        columns: [
            {
                label: t('year'),
                field: 'year',
                sort: 'asc',
                width: 150,
                rows: []
            },
            {
                label: t('payment_UAH/Ha'),
                field: 'payout',
                sort: 'asc',
                width: 150,
                rows: []
            }
        ]
    };

    const chartOptions = {
        legend: {position: 'none', alignment: 'start'},
        seriesType: 'bars',
        vAxis: {
            format: '##,##%'
        },
        series: {1: {type: 'line'}, 2: {type: 'line', areaOpacity: 0.5}, 3: {type: 'line', areaOpacity: 1}},
        chart: {},
    };
    const chartData = [
        [
            t('year'),
            {role: 'tooltip'},
            t('the_average_value_of_moisture'),
            {role: 'style'},
            {role: 'tooltip'},
            t('condition_insured_event'),
            {role: 'style'},
            {role: 'tooltip'},
            t('condition_of_maximum_payment'),
            {role: 'style'},
            {role: 'tooltip'},
            t('total_deficit_of_moisture_in_the_soil'),
            {role: 'style'},
        ]
    ];

    let conditionOfInsuranceValue = 0.0, maxInsuranceSum = 0, conditionOfMaxPayment = 0, deficitPerPoint = 0;

    const [insuranceData, setInsuranceData] = useState(insurance.state.value);

    useEffect(() => {
        const subscription = insurance.state.subscribe(e => {
            setInsuranceData(e);
        });
        if (insuranceParams.step > 1) {
            insurance.handleChange(insuranceParams);
        } else if (insuranceData.step === 0) {
            insurance.handleChange('step', 1);
            insurance.handleChange('type', 'drought');
        }

        return () => subscription.unsubscribe();
    }, []);

    if (insuranceData.selectedInsurancePlan >= 0 && Object.keys(insuranceData.statistics).length > 0 && insuranceData.step === 4) {
        const chanceOfInsuranceKey = Object
            .keys(insuranceData.insuranceSums[insuranceData.selectedInsurancePlan].chanceOfInsurance)[0];
        conditionOfInsuranceValue = insuranceData
            .insuranceSums[insuranceData.selectedInsurancePlan]
            .chanceOfInsurance[chanceOfInsuranceKey];
        const averageValueGroupedByDatesBySelectedPeriod = insuranceData
            .insuranceSums[insuranceData.selectedInsurancePlan]
            .averageValueGroupedByDatesBySelectedPeriod;
        const chanceOfInsurance = insuranceData
            .insuranceSums[insuranceData.selectedInsurancePlan]
            .chanceOfInsurance;
        maxInsuranceSum = insuranceData
            .insuranceSums[insuranceData.selectedInsurancePlan]
            .maxInsuranceSum;
        const conditionOfInsuranceLower = insuranceData
            .insuranceSums[insuranceData.selectedInsurancePlan]
            .conditionOfInsuranceLower;
        deficitPerPoint = (insuranceData.insuranceSums[insuranceData.selectedInsurancePlan]['deficitPerPoint'])
            .toFixed(2);
        conditionOfMaxPayment = conditionOfInsuranceLower[chanceOfInsuranceKey];
        const tableDataRows = {};

        const planValuesList = [];
        planValuesList.push(Object.values(insuranceData.statistics));
        // eslint-disable-next-line array-callback-return
        insuranceData.insuranceSums.map(e => {
            planValuesList.push(Object.values(e.averageValueGroupedByDatesBySelectedPeriod));
            planValuesList.push(Object.values(e.chanceOfInsurance));
            planValuesList.push(Object.values(e.conditionOfInsuranceLower));
        })
        chartOptions.vAxis.minValue = 0;
        chartOptions.vAxis.ticks = separateLineByTicks(
            0,
            getMaxValOfArrays(...planValuesList),
            6
        );

        for (let year in insuranceData.statistics) {
            chartData.push([
                year,
                t('condition_of_maximum_payment') + ' ' + conditionOfInsuranceLower[year].toFixed(2) + '%',
                insuranceData.statistics[year],
                getColor(
                    insuranceData.statistics[year],
                    conditionOfInsuranceValue
                ),
                t('total_deficit_of_moisture_in_the_soil')
                    + ' ' + insuranceData.statistics[year].toFixed(2) + '%',
                averageValueGroupedByDatesBySelectedPeriod[year],
                '#6173F4',
                t('the_average_value_of_moisture') + ' '
                    + averageValueGroupedByDatesBySelectedPeriod[year].toFixed(2) + '%',
                chanceOfInsurance[year],
                '#D96459',
                t('condition_insured_event') + ' ' + chanceOfInsurance[year].toFixed(2) + '%',
                conditionOfInsuranceLower[year],
                '#93959D',
            ]);
            if (year >= 2010) {
                tableDataRows[year] = insuranceData
                    .insuranceSums[insuranceData.selectedInsurancePlan]['indemnity_by_year_range'][year];
            }
        }

        Object.keys(tableDataRows).sort(function (a, b) {
            return b - a
        }).forEach(function (key) {
            tableData.columns[0].rows.push(key)
            tableData.columns[1].rows.push((tableDataRows[key] / (isPriceMultiplyRequired ? PRICE_MULTIPLIER : 1)).toFixed(2))
        });

        chartData.sort((a, b) => (a[0] < b[0]) ? 1 : (a[0] > b[0]) ? -1 : 0);
    }

    let form = ('');

    let headerTitle = '';

    switch (insuranceData.step) {
        case 2:
            headerTitle = 'chose_period_of_insurance_label';
            form = (<Period infoBlock1={t('period-info-block-1')}  infoBlock2={t('period-info-block-2')} />);
            break;
        case 3:
            headerTitle = 'mark_field_on_the_map';
            form = (<CoordinatesPicker infoBlock1={t('coordinates-info-block-1')} infoBlock2={t('coordinates-info-block-1')} calculateOnSubmit={true}/>);
            break;
        case 4:
            headerTitle = 'chose_the_level_of_covering';
            const overPayment = (
                <div className="coating-about-item-text">{t('payment_by_deficit')}<br/>
                    <span>{(deficitPerPoint / (isPriceMultiplyRequired ? PRICE_MULTIPLIER : 1)).toFixed(2)}</span> {t('grn/ha')}
                </div>
            );

            form = (<CoveringPicker
                buttons={buttons}
                tableData={tableData}
                chartOptions={chartOptions}
                triggerValue={t('lower') + ' ' + (conditionOfInsuranceValue.toFixed(2) + " %")}
                chartData={chartData}
                overPayment={overPayment}
                conditionOfMaxPayment={conditionOfMaxPayment.toFixed(2) + ' %'}
                maxInsuranceSum={maxInsuranceSum}
                chartTitle={t('average_daily_moisture')}
            />);
            break;
        default:
        case 1:
            headerTitle = 'chose_layer_label';
            form = (<GroundLayer
                infoBlock1={t('drought-ground-info-block-1')}
                infoBlock2={t('drought-ground-info-block-2')}
                column={
                    DroughtLayerOptions
                }/>);
            break;
    }

    const HeadTitle = () => insuranceData.step > 0 ? (<CalculatorHeader headerTitle={headerTitle}/>) : '';

    const calcStyle = insuranceData.step === 4 ? "calculate-wrapper overflow" : "calculate-wrapper";

    return (
        <React.Fragment>
            <HeadTitle/>
            <div className={calcStyle}>
                {form}
            </div>
        </React.Fragment>
    );
}

export default withTranslation()(insuranceHOC()(Drought));
