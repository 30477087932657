import React, {Suspense} from 'react';
import './App.css';
import {
    BrowserRouter as Router,
    Route,
    Switch
} from "react-router-dom";
import Home from './components/home/home';
import CalculateInsurance from './components/calculate-insurance/calculate-insurance';
import Header from './components/header';
import Footer from "./components/home/footer";
import "flatpickr/dist/flatpickr.css";
import SuccessOrder from "./components/calculate-insurance/success-order";
import MetaTags from "./components/MetaTags";
import CortevaProgram from "./components/home/corteva-program";
import Preloader from "./components/preloader";
import {BaseProgramButtons, BasePrograms, CortevaPackageButtons, CortevaPackagePrograms} from "./utils/ProgramsConfig";

const Loader = () => (
    <div className="App" style={{'position': 'absolute',
        'top': '50%',
        'left': '50%',
        'marginRight': '-50%',
        'transform': 'translate(-50%, -50%)'}}>
        <img src="/assets/img/logo.svg" className="App-logo" alt="logo" />
        <div>loading...</div>
    </div>
);

const App = (props) => {
    return (
        <Suspense fallback={<Loader/>}>
            <Preloader/>
            <React.Fragment>
                <Router>
                    <MetaTags/>
                    <Header/>
                    <Switch>
                        <Route exact path="/" component={Home}></Route>
                        <Route path="/login">
                            <div>Login</div>
                        </Route>
                        <Route path="/success" component={SuccessOrder}></Route>
                        <Route path="/calculate-insurance" component={() => <CalculateInsurance buttonsList={BaseProgramButtons} programs={BasePrograms}/>}></Route>
                        <Route path="/calculate-corteva-package" component={() => <CalculateInsurance buttonsList={CortevaPackageButtons} programs={CortevaPackagePrograms}/>}></Route>
                        <Route path="/corteva-program" component={CortevaProgram}></Route>
                    </Switch>
                    <Footer/>
                </Router>
            </React.Fragment>
        </Suspense>
    );
};

export default App;
