import React from 'react';

const Footer = () => (
    <div className="footer">
        <div className="container">
            <div className="footer-content">
                <div className="footer-logo">
                    <a href="/"><img src="/assets/img/footer-logo.svg" alt=""/></a>
                </div>
                <div className="footer-info">
                    <div className="footer-info-col">
                        {/*<div className="info-col-title">Products</div>*/}
                        {/*<a href="/" className="info-col-link">Individual Accounts</a>*/}
                        {/*<a href="/" className="info-col-link">Pricing</a>*/}
                        {/*<a href="/" className="info-col-link">Program</a>*/}
                    </div>
                    <div className="footer-info-col">
                        {/*<div className="info-col-title">Company</div>*/}
                        {/*<a href="/" className="info-col-link">About</a>*/}
                        {/*<a href="/" className="info-col-link">Technology</a>*/}
                        {/*<a href="/" className="info-col-link">Contact</a>*/}
                    </div>
                    <div className="footer-info-col">
                        {/*<div className="info-col-title">Support</div>*/}
                        {/*<a href="/" className="info-col-link">Help Center</a>*/}
                        {/*<a href="/" className="info-col-link">Status</a>*/}
                    </div>
                    <div className="footer-info-col">
                        <div className="info-col-title">Social</div>
                        <div className="social">
                            <a href="https://www.facebook.com/MeteoControlUkraine-106288731147788" rel="noindex dofollow noopener noreferrer" target="_blank" className="social-link">
                                <img src="/assets/img/facebook.svg" alt=""/>
                            </a>
                            <a href="https://www.linkedin.com/company/meteocontrol-ai" rel="noindex dofollow noopener noreferrer" target="_blank" className="social-link">
                                <img src="/assets/img/linkedin.png" alt=""/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright-bar">
                <div className="copyright">&copy; MeteoControl 2021. All Rights Reserved</div>
                <div className="copyright-link">
                    {/*<a href="/" className="copyright-link-item">Terms & Conditions</a>*/}
                    {/*<a href="/" className="copyright-link-item">Privacy Policy</a>*/}
                </div>
            </div>
        </div>
    </div>
);

export default Footer;
