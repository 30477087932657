import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

const options = {
    fallbackLng: 'ua',
    load: 'languageOnly',
    saveMissing: true,
    wait: process && !process.release,
    debug: false,
    initImmediate: false,
    preload: ["ua", "en"],
    useLocalStorage: true
}

// for browser use http backend to load translations and browser lng detector
if (process && !process.release) {
    i18n
        .use(Backend)
        .use(initReactI18next)
        .use(LanguageDetector);
}

// initialize if not already initialized
if (!i18n.isInitialized) {
    i18n.init(options);
}

export default i18n;
