import React, {useEffect, useState} from 'react';
import Navbar from './navbar'
import {withRouter} from "react-router";

const Header = (props) => {
    const [
        {
            showTransparent
        }, updateFormObj
    ] = useState({
        showTransparent: true
    });

    useEffect(() => {
        // Listen for new scroll events, here we debounce our `storeScroll` function
        document.addEventListener('scroll', () => {            updateFormObj({showTransparent: window.scrollY === 0})
        }, { passive: true });
    }, []);

    return (
        <header className={"header" + (showTransparent ? ' transparent' : ' fixed')}>
            <Navbar isScrolled={!showTransparent}/>
        </header>
    );
}

export default withRouter(Header);
