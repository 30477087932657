import React from "react";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import Insurance from "../../services/insurance";

const insurance = Insurance.getInstance();

const TemperaturePicker = (props) => {
    const {t} = props;

    const isValidTemperature = () => insurance.state.value.temperature > 0;

    return (
        <div className="calculate-wrapper">
            <div className="calculate-insurance-contact-form">
                <div className="contact-form-wrapper" style={{textAlign: 'center'}}>
                    <input
                        name="temperature"
                        defaultValue={null}
                        onChange={e => insurance.handleChange('temperature', e.target.value)}
                        style={{
                            width: '500px',
                            height: '50px',
                            background: 'white'
                        }}
                        type="number"
                    />
                    <div className="pt-3"></div>
                    <div className="text-center">
                        <Link to={'#'} style={{margin: '0 auto'}} className={"btn-default " + (isValidTemperature() ? '' : 'disabled') } onClick={() => {
                            insurance.handleChange('step', 4);
                        }}>
                            {t('confirm')}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(TemperaturePicker);
