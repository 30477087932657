import React from 'react';
import {getParamsFromUrl} from "./url";
import {withRouter} from "react-router";
import Insurance from "../services/insurance";

const insurance = Insurance.getInstance();

export const insuranceHOC = () => Component => {
    const encodeQueryData = (data) => {
        const ret = [];
        for (let d in data)
            ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
        return ret.join('&');
    }

    return withRouter((props) => {
        const queryParams = getParamsFromUrl(props.location.search);
        const statePart = {};
        let step = 1;
        if (typeof queryParams['layer'] !== 'undefined') {
            statePart['layer'] = queryParams['layer'];
            step++;
        }
        if (typeof queryParams['lon'] !== 'undefined' && typeof queryParams['lat'] !== 'undefined') {
            statePart['lon'] = queryParams['lon'];
            statePart['lat'] = queryParams['lat'];
            step++;
        }
        if (typeof queryParams['from'] !== 'undefined' && typeof queryParams['till'] !== 'undefined') {
            statePart['from'] = queryParams['from'];
            statePart['till'] = queryParams['till'];
            step++;
        }
        if (typeof queryParams['crop'] !== 'undefined') {
            statePart['crop'] = queryParams['crop'];
        }
        if (typeof queryParams['oblName'] !== 'undefined') {
            statePart['oblName'] = queryParams['oblName'];
        }
        if (typeof queryParams['temperature'] !== 'undefined') {
            statePart['temperature'] = queryParams['temperature'];
            step++;
        }
        if (typeof queryParams['type'] !== 'undefined') {
            statePart['type'] = queryParams['type'];
        } else {
            statePart['type'] = props.location.pathname.split('/').slice(-1).pop();
        }
        statePart['step'] = step;

        const insuranceState = insurance.state.value;
        const params = {};
        if (insuranceState.layer !== null) {
            params['layer'] = insuranceState.layer;
        }
        if (insuranceState.lon !== null && insuranceState.lat !== null) {
            params['lon'] = insuranceState.lon;
            params['lat'] = insuranceState.lat;
        }
        if (insuranceState.from !== null && insuranceState.till !== null) {
            params['from'] = insuranceState.from;
            params['till'] = insuranceState.till;
        }
        if (insuranceState.temperature !== null) {
            params['temperature'] = insuranceState.temperature;
        }
        if (insuranceState.crop !== null) {
            params['crop'] = insuranceState.crop;
        }
        if (insuranceState.oblName !== null) {
            params['oblName'] = insuranceState.oblName;
        }
        if (insuranceState.type !== null) {
            params['type'] = insuranceState.type;
        }
        if (Object.keys(params).length > 0) {
            window.history.replaceState(null, null, "?" + encodeQueryData(params));
        }

        const newProps = {
            ...props,
            insuranceParams: {}
        }
        newProps['insuranceParams'] = statePart;

        return <Component {...newProps} />
    })
}
