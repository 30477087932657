import React from "react";
import {Link} from "react-router-dom";
import {withTranslation} from "react-i18next";
import Flatpickr from "react-flatpickr";
import Ukrainian from 'flatpickr/dist/l10n/uk.js';
import InfoBlock from "./info-block";
import MobileButtonBack from "../shared/mobile-button-back";
import Insurance from "../../services/insurance";


const insurance = Insurance.getInstance();

const toYmd = function (date) {
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();

    return [date.getFullYear(),
        (mm > 9 ? '' : '0') + mm,
        (dd > 9 ? '' : '0') + dd
    ].join('-');
};

const Period = (props) => {
    const {t, i18n} = props;
    let insuranceData = insurance.state.value;
    insurance.state.subscribe(e => insuranceData = e);
    const date = new Date();
    date.setDate(date.getDate() + 14);
    const startMonth = date.getUTCMonth() + 1;
    const today = [date.getFullYear(), startMonth < 10 ? '0' + startMonth : startMonth, date.getUTCDate()].join('-');

    const isValidDate = () => +(new Date(insuranceData.from)) < +(new Date(insuranceData.till));

    const tillDate = insuranceData.from !== null ? new Date(insuranceData.from) : date;
    tillDate.setDate(tillDate.getDate() + 30);
    let tillStartMonth = tillDate.getUTCMonth() + 1;
    const tillDateMinStr = [tillDate.getFullYear(), tillStartMonth < 10 ? '0' + tillStartMonth : tillStartMonth, tillDate.getUTCDate()].join('-');

    tillDate.setDate(tillDate.getDate() + 61);
    tillStartMonth = tillDate.getUTCMonth() + 1;
    const tillDateMaxStr = [tillDate.getFullYear(), tillStartMonth < 10 ? '0' + tillStartMonth : tillStartMonth, tillDate.getUTCDate()].join('-');

    const lng = ['ua', 'ru'].indexOf(i18n.language) !== -1 ? 'uk' : i18n.language;
    return (
        <React.Fragment>
            <div className="insurance-period">
                <div className="datepicker-container">
                    <div className="datepicker-wrapper">
                        <div className="datepicker-text">{t('period_from')}</div>
                        <div className="datepicker-inner">
                            <Flatpickr
                                placeholder={t('chose_date')}
                                className={'datepicker'}
                                options={{
                                    minDate: today,
                                    locale: lng,
                                    inline: true,
                                    dateFormat: "Y-m-d",
                                    enableTime: false,
                                }}
                                onChange={date => insurance.handleChange('from', toYmd(date[0]))}
                            />
                            <img className="datepicker-icon" src="/assets/img/calendar.svg" alt=""/>
                        </div>
                    </div>
                    <div className="datepicker-wrapper">
                        <div className="datepicker-text">{t('period_till')}</div>
                        <div className="datepicker-inner">
                            <Flatpickr
                                placeholder={t('chose_date')}
                                className={'datepicker'}
                                options={{
                                    minDate: tillDateMinStr,
                                    maxDate: tillDateMaxStr,
                                    locale: lng,
                                    inline: true,
                                    dateFormat: "Y-m-d",
                                    enableTime: false,
                                }}
                                onChange={date => insurance.handleChange('till', toYmd(date[0]))}
                            />
                            <img className="datepicker-icon" src="/assets/img/x-mark.svg" alt=""/>
                        </div>
                    </div>
                </div>
                <div className="insurance-period-btn">
                    <Link to={'#'} className={"btn-default " + (isValidDate() ? '' : 'disabled')}
                          onClick={() => insurance.handleChange('step', insuranceData.step + 1)}>{t('confirm_period')}</Link>
                </div>
            </div>
            <MobileButtonBack/>
            <InfoBlock infoBlock1={props.infoBlock1} infoBlock2={props.infoBlock2}/>
        </React.Fragment>
    );
}

export default withTranslation()(Period)
