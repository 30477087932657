import React, {useEffect, useState} from "react";
import Period from "./period";
import CoveringPicker, {PRICE_MULTIPLIER} from "./covering-picker";
import TemperaturePicker from "./temperature-picker";
import CoordinatesPicker from "./coordinates-picker";
import {withTranslation} from "react-i18next";
import CalculatorHeader from "../shared/calculator_header";
import {getMaxValOfArrays, separateLineByTicks} from "../../utils/MathFunc";
import {checkIsPriceMultiplyRequired} from "../../utils/Conditions";
import Insurance from "../../services/insurance";
import {insuranceHOC} from "../../utils/insuranceHOC";

const insurance = Insurance.getInstance();

export const TemperatureLayerOptions = {
    'index': 4,
    'label': 'temperature',
    'cardModifier': "droughts",
    'imgs': ['calc-select-2', 'calc-select-5'],
    'link': '/calculate-insurance/temperature',
    'availability': true,
    'programs': [
        'chose_program',
        'chose_period_of_insurance',
        'chose_location',
        'chose_temperature',
        'chose_cover',
    ]
};

const Temperature = (props) => {
    const {t, i18n, insuranceParams} = props;
    const isPriceMultiplyRequired = checkIsPriceMultiplyRequired(i18n.language);
    const getColor = (value, trigger) => {
        return  trigger < value ? '#D96459' : '#7CCFC6';
    };
    const buttons = [
        {
            text: t('reliable_owner'),
            value: 0,
            percents: 45
        },
        {
            text: t('safe'),
            value: 1,
            percents: 25
        },
        {
            text: t('sufficient'),
            value: 2,
            percents: 15
        },
        {
            text: t('optimist'),
            value: 3,
            percents: 5
        }
    ];

    let form = ('');

    const [insuranceData, setInsuranceData] = useState(insurance.state.value);

    useEffect(() => {
        const subscription = insurance.state.subscribe(e => {
            setInsuranceData(e);
        });
        if (insuranceParams.step > 1) {
            insurance.handleChange(insuranceParams);
        } else if (insuranceData.step === 0) {
            insurance.handleChange('step', 1);
            insurance.handleChange('type', 'temperature');
        }

        return () => subscription.unsubscribe();
    }, []);

    const chartOptions = {
        legend: {position: 'none', alignment: 'start'},
        seriesType: 'bars',
        vAxis: {
            minValue: 0,
            format: '##,##%'
        },
        series: { 1: { type: 'line' }, 2: { type: 'line' }, 3: { type: 'line' }},
        chart: {},
    };
    const chartData = [
        [
            t('year'),
            t('cases_hot_days'),
            {role: 'style'},
            t('condition_days_more'),
            {role: 'style'},
            t('condition_maximum_insurance'),
            {role: 'style'},
            t('average_excessive_temperature'),
            {role: 'style'},
        ]
    ];
    const tableData = {
        columns: [
            {
                label: t('year'),
                field: 'year',
                sort: 'asc',
                width: 150,
                rows: []
            },
            {
                label: t('payment_UAH/Ha'),
                field: 'payout',
                sort: 'asc',
                width: 150,
                rows: []
            }
        ]
    };

    let chanceOfInsurance = 0, conditionOfMaxPayment = 0, maxInsuranceSum = 0, deficitPerPoint = 0;
    if (
        insuranceData.selectedInsurancePlan >= 0
        && Object.keys(insuranceData.statistics).length > 0
        && insuranceData.step === 4
    ) {
        chanceOfInsurance = insuranceData.insuranceSums[insuranceData.selectedInsurancePlan].chance_condition;
        const conditionOfMaxPaymentKey = Object.keys(insuranceData.insuranceSums[insuranceData.selectedInsurancePlan]
            .maximum_payment_of_insurance_indemnity)[0];
        conditionOfMaxPayment = insuranceData.insuranceSums[insuranceData.selectedInsurancePlan]
            .maximum_payment_of_insurance_indemnity[conditionOfMaxPaymentKey];
        maxInsuranceSum = insuranceData
            .insuranceSums[insuranceData.selectedInsurancePlan]
            .maxInsuranceSum;
        deficitPerPoint = (insuranceData.insuranceSums[insuranceData.selectedInsurancePlan]['deficitPerPoint'])
            .toFixed(2);
        const tableDataRows = {};

        const avgStatistics = Object.values(insuranceData.statistics)
            .reduce((a, v) => a + v, 0) / Object.keys(insuranceData.statistics).length;

        const planValuesList = [];
        planValuesList.push(Object.values(insuranceData.statistics));
        // eslint-disable-next-line array-callback-return
        insuranceData.insuranceSums.map(e => {
            planValuesList.push(Object.values(e.insured_event_number_of_days));
            planValuesList.push(Object.values(e.maximum_payment_of_insurance_indemnity));
        })
        chartOptions.vAxis.ticks = separateLineByTicks(
            0,
            getMaxValOfArrays(...planValuesList),
            6
        );

        for (let year in insuranceData.statistics) {
            chartData.push([
                year,
                insuranceData.statistics[year],
                getColor(
                    insuranceData.statistics[year],
                    chanceOfInsurance
                ),
                insuranceData.insuranceSums[insuranceData.selectedInsurancePlan].insured_event_number_of_days[year],
                '#D96459',
                insuranceData.insuranceSums[insuranceData.selectedInsurancePlan].maximum_payment_of_insurance_indemnity[year],
                '#93959D',
                avgStatistics,
                '#6173F4'
            ]);
            if (year >= 2010) {
                tableDataRows[year] = insuranceData
                    .insuranceSums[insuranceData.selectedInsurancePlan]['sum_excess_of_hot_days'][year];
            }
        }

        Object.keys(tableDataRows).sort(function(a, b){return b-a}).forEach(function(key) {
            tableData.columns[0].rows.push(key)
            tableData.columns[1].rows.push(tableDataRows[key].toFixed(2))
        });
    }

    let headerTitle = '';
    switch (insuranceData.step) {
        case 1:
            headerTitle = 'chose_period_of_insurance_label';
            form = (<Period infoBlock1={t('period-info-block-1')}  infoBlock2={t('period-info-block-2')}/>);
            break;
        case 2:
            headerTitle = 'mark_field_on_the_map';
            form = (<CoordinatesPicker infoBlock1={t('coordinates-info-block-1')} infoBlock2={t('coordinates-info-block-1')} calculateOnSubmit={false}/>);
            break;
        case 3:
            headerTitle = 'enter_a_hot_day_criterion';
            form = (<TemperaturePicker/>);
            break;
        case 4:
            headerTitle = 'chose_the_level_of_covering';
            const overPayment = (
                <div className="coating-about-item-text">{t('amount_payment_excessive_heat')}<br/>
                    <span>{(deficitPerPoint / (isPriceMultiplyRequired ? PRICE_MULTIPLIER : 1)).toFixed(2)}</span> {t('grn/ha')}
                </div>
            );

            form = (<CoveringPicker
                chartData={chartData}
                tableData={tableData}
                chartOptions={chartOptions}
                buttons={buttons}
                maxInsuranceSum={maxInsuranceSum}
                overPayment={overPayment}
                conditionOfMaxPayment={ conditionOfMaxPayment + ' ' + t('days_and_more')}
                triggerValue={(chanceOfInsurance) + ' ' + t('days_and_more')}
                chartTitle={t('amount_payment_excessive_heat') + ' '}
            />);
            break;
        default:
            form = (<Period infoBlock1={t('period-info-block-1')}  infoBlock2={t('period-info-block-2')}/>);
            break;
    }
    const HeadTitle = () => insuranceData.step > 0 ? (<CalculatorHeader headerTitle={headerTitle}/>) : '';

    return (
        <React.Fragment>
            <HeadTitle/>
            <div className="calculate-wrapper">
                {form}
            </div>
        </React.Fragment>
    );
}

export default withTranslation()(insuranceHOC()(Temperature));
