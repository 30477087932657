import React, {useEffect, useState} from "react";
import {Map, GoogleApiWrapper, Marker} from 'google-maps-react';
import AsyncSelect from 'react-select/async';
import {debounce} from "../../utils/callback";
import {xyzToBounds} from "../../utils/shape";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import InfoBlock from "./info-block";
import MobileButtonBack from "../shared/mobile-button-back";
import Insurance from "../../services/insurance";

const insurance = Insurance.getInstance();

function CoordinatesPicker(props) {
    const {t} = props;
    const mapStyles = {
        width: '100%',
        height: '100%',
    };
    const [{lng, lat, zoom, zoomValue}, updateFormObj] = useState({
        lng: null,
        lat: null,
        zoom: {
            lat: 49.29237484899109,
            lng: 31.239466860096556
        },
        zoomValue: 7
    });
    const markers = () => {
        if (!lng || !lat) {
            return ('');
        }
        return (
            <Marker key={'t'} id={'d'} position={{
                lat: lat,
                lng: lng
            }}/>
        )
    };

    const [insuranceData, setInsuranceData] = useState(insurance.state.value);

    useEffect(() => {
        const subscription = insurance.state.subscribe(e => {
            setInsuranceData(e);
        });

        return () => subscription.unsubscribe();
    });

    let loadOptions = (inputValue, callback) => {
        if (inputValue.length < 3) {
            callback([]);
            return;
        }

        const res = insurance.loadDistricts(inputValue || 't');
        res.then(e => callback(e));
    };

    loadOptions = debounce(loadOptions, 2000);

    const handleInputChange = (newValue) => {
        return newValue;
    };

    const handleSelect = (e) => {
        const coordinates = e.value.split(':');
        updateFormObj({
            lng,
            lat,
            zoom: {
                lng: parseFloat(coordinates[0]),
                lat: parseFloat(coordinates[1]),
            },
            zoomValue: 12
        })
    }

    return (
        <React.Fragment>
            <div className="insurance-map">
                <div className="insurance-map-wrapper">
                    <div style={{position: 'relative', height: '600px', width: '100%'}}>
                        <Map
                            onClick={(e, q, a) => {
                                let oblName = null;
                                const geocoder = new e.google.maps.Geocoder();
                                geocoder.geocode({location: {lng: a.latLng.lng(), lat: a.latLng.lat()}}, (res, st) => {
                                    res.filter(e => e.types.indexOf('administrative_area_level_1') !== -1).map(e => {
                                        if (e.address_components.length > 0) {
                                            oblName = e.address_components[0].short_name.split(' ')[0];
                                        }

                                        insurance.handleChange('lon', a.latLng.lng());
                                        insurance.handleChange('lat', a.latLng.lat());
                                        insurance.handleChange('oblName', oblName);
                                    })
                                });

                                updateFormObj({lat: a.latLng.lat(), lng: a.latLng.lng(), zoom, zoomValue});
                            }}
                            google={props.google}
                            onReady={(maps, map) => {
                                const cads = new maps.google.maps.ImageMapType({
                                    getTileUrl: function (coord, zoom) {
                                        return "https://m2.land.gov.ua/geowebcache/service/wms?tiled=true&SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=kadastr&TILED=true&STYLES=&SRS=EPSG%3A900913&WIDTH=256&HEIGHT=256&CRS=EPSG%3A900913&BBOX=" + xyzToBounds(coord.x, coord.y, zoom).join(",") + ""
                                    },
                                    name: "cads",
                                    minZoom: 7,
                                    maxZoom: 19,
                                    opacity: 0.8
                                });
                                const sowing = new maps.google.maps.ImageMapType({
                                    getTileUrl: function (coord, zoom) {
                                        return "https://service.geoportalua.com/arcgis/rest/services/WB/Klasif_posiviv_2019/MapServer/export?F=image&FORMAT=PNG32&TRANSPARENT=true&LAYERS=show%3A-1%2C-1%2C-1%2C0&DPI=192&SIZE=512%2C512&BBOX=" + xyzToBounds(coord.x, coord.y, zoom).join(",") + "&BBOXSR=102100&IMAGESR=102100";
                                    },
                                    name: "sowing",
                                    minZoom: 7,
                                    maxZoom: 19,
                                    opacity: 0.1
                                });
                                map.overlayMapTypes.push(cads)
                                map.overlayMapTypes.push(sowing)
                            }}
                            zoom={zoomValue}
                            style={mapStyles}
                            mapTypeControlOptions={{
                                style: props.google.maps.MapTypeControlStyle.DEFAULT,
                                mapTypeIds: ['hybrid', 'terrain'],
                                position: props.google.maps.ControlPosition.BOTTOM_LEFT
                            }}
                            streetViewControl={false}
                            initialCenter={zoom}
                            center={zoom}
                        >{markers()}</Map>
                        <form className="map-search">
                            <AsyncSelect
                                className={'search'}
                                cacheOptions
                                placeholder={t('settlement')}
                                loadOptions={loadOptions.bind(this)}
                                defaultOptions
                                onInputChange={handleInputChange}
                                onChange={handleSelect}
                            />
                        </form>
                    </div>
                </div>
                <div className="map-btn">
                    <Link to={'#'} onClick={() => {
                        insurance.handleChange('step', insuranceData.step + 1)
                    }} className={"btn-default " + (lng !== null ? '' : 'disabled')}>{t('confirm')}</Link>
                </div>
            </div>
            <MobileButtonBack/>
            <InfoBlock infoBlock1={props.infoBlock1} />
        </React.Fragment>
    );

}

export default withTranslation()(GoogleApiWrapper({
    apiKey: 'AIzaSyBJaahLA3O6sVK4tQ-zWgqO59DlrB-u-ks',
    mapTypeControl: false,
})(CoordinatesPicker));
