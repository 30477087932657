import {withTranslation} from "react-i18next";
import React, {useEffect, useLayoutEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {withRouter} from "react-router";

const Navbar = (props) => {
    const {t, i18n, location, isScrolled} = props;
    const defaultLang = 'ua';
    const langs = [
        'ua',
        'en'
    ];
    const [open, setOpen] = React.useState(false);
    const [{currentLng}, updateState] = useState({
        currentLng: i18n.language
    });
    const isShowFinancingButton = ['/'].indexOf(location.pathname) !== -1 && !isScrolled;

    if (open) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = 'unset';
    }

    useLayoutEffect(() => {
        function updateSize() {
            if (window.innerWidth > 991) {
                document.body.style.overflow = 'unset';
            }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
        updateState({currentLng: lng});
    };

    useEffect(() => {
        if (langs.indexOf(i18n.language) === -1) {
            changeLanguage(defaultLang);
        }
    }, []);

    return (
        <nav className="header-nav container">
            <div className="header-logo">
                <Link to="/"><img src="/assets/img/logo.svg" alt=""/></Link>
            </div>
            <nav className={'header-menu ' + (open ? 'active' : '')}>
                {(isShowFinancingButton ? (<a href="https://www.agrifinance.io/" className="btn btn-hidden">{t('menu_receive_financing')}</a>) : '')}
                <Link className="btn btn-main" replace={true} to="/calculate-insurance" onClick={(e) => setOpen(false)}>{t('calculate')}</Link>
                <div className="lang">
                    {langs.map((e, i) => (
                        <div key={i} className={"lang-btn " + (currentLng === e ? "selected" : "")}
                             onClick={() => changeLanguage(e)}>{e.toUpperCase()}</div>
                    ))}
                </div>
            </nav>
            <div className="burger-btn" onClick={() => setOpen(!open)}>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </nav>
    );
}

export default withTranslation()(withRouter(Navbar));
