import React, {useLayoutEffect, useState} from "react";
import {PRICE_MULTIPLIER} from "./covering-picker";
import {withTranslation} from "react-i18next";
import SwiperPricingList from "./swiper-pricing-list";
import Insurance from "../../services/insurance";

const insurance = Insurance.getInstance();

const PricingList = (props) => {
    const {t, isPriceMultiplyRequired, buttons, selectedInsurancePlan, insuranceSums} = props;

    const [showSwiper, setShowSwiper] = useState(true);

    useLayoutEffect(() => {
        function updateSize() {
            setShowSwiper(window.innerWidth <= 991);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    if (showSwiper) {
        return (<SwiperPricingList
            isPriceMultiplyRequired={isPriceMultiplyRequired}
            buttons={buttons}
            selectedInsurancePlan={selectedInsurancePlan}
            insuranceSums={insuranceSums}
        />);
    }

    return (
        <div className="calculate-coating-container">
            <div className="coating-pricing-wrapper">
                {buttons.map((column, index) => (
                    <div
                        key={index}
                        className={"pricing-item " + (selectedInsurancePlan === index ? 'active' : '')}
                        onClick={() => {
                            insurance.handleChange('selectedInsurancePlan', index);
                        }}>
                        <div className="pricing-item-inner">
                            <h4 className="pricing-item-title">{column.text}</h4>
                            <div className="pricing-item-price">
                                            <span>
                                                {!!insuranceSums[column.value] ? (insuranceSums[column.value]['sum_per_ha'] / (
                                                    isPriceMultiplyRequired ? PRICE_MULTIPLIER : 1
                                                )).toFixed(2) : ''}
                                            </span> <br/>{t('grn/ha')}
                            </div>
                            <div className="pricing-item-desc">{t('cover')} <p/>
                                <span>{column.percents}%</span> {t('worst_cases')}
                            </div>
                        </div>
                        <div className="pricing-item-overflow"></div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default withTranslation()(PricingList);
