import React, {useEffect} from 'react';
import {withTranslation} from "react-i18next";
import Insurance from "../../../services/insurance";
import {Chart} from "react-google-charts";

const insurance = Insurance.getInstance();

const PackageCalculationResult = (props) => {
  const {t, deficits, periods, payouts, i18n, emptyChartIndexes} = props;
  let chartBlockClassGrid = 'w100p';

  let chartsFirstType = [];
  let chartsSecondType = [];
  let periodList = [];
  let deficitsList = [];
  let payoutList = [];

  useEffect(() => {
    const subscription = insurance.state.subscribe(e => {
    });
    insurance.uploadCalculations();

    return () => subscription.unsubscribe();
  }, []);

  const getParsedDate = (strDate) => {
    var strSplitDate = String(strDate).split(' ');
    var date = new Date(strSplitDate[0]);
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    date = dd + "." + mm + "." + yyyy;
    return date.toString();
  }

  const createFirstChart = (position) => {
    chartsFirstType.push((
      <div style={{zIndex: 999}}>
        <Chart
          chartLanguage={i18n.language === 'ua' ? 'uk' : i18n.language}
          width={'100%'}
          height={'100%'}
          chartType="AreaChart"
          loader={<div>{t('loading_chart')}</div>}
          data={props.chartData[position]}
          options={props.chartOptions[position]}
        />
      </div>
    ));

    deficitsList.push((
      <p className="p__bold fz18">Розрахунковий рівень дефіциту у періоді: <span
        className="text-blue">{(deficits[position] * 100).toFixed(0)}%</span></p>
    ));
    periodList.push((
      <React.Fragment>{getParsedDate(periods[position].start)} - {getParsedDate(periods[position].end)}</React.Fragment>));
    payoutList.push(
      <p className="p__bold fz18">% {t('percent_of_payment_from_insured_sum')}**: <span
        className="text-blue">{payouts[position]}%</span></p>
    );
  }

  const createSecondChat = (position) => {
    chartsSecondType.push((
      <div style={{zIndex: 999}}>
        <Chart
          chartLanguage={i18n.language === 'ua' ? 'uk' : i18n.language}
          width={'100%'}
          height={'100%'}
          chartType="ComboChart"
          loader={<div>{t('loading_chart')}</div>}
          data={props.chartData[position]}
          options={props.chartOptions[position]}
        />
      </div>
    ));
  }

  const isAllEmpty = Object.values(emptyChartIndexes).filter(e => e === false).length === 0;
  const chartIterator = isAllEmpty ? [] : props.chartData.length > 2 ? [0, 1] : [0];

  if (typeof props.chartData[0] !== 'undefined') {
    createFirstChart(0);
  }

  if (typeof props.chartData[1] !== 'undefined') {
    createSecondChat(1);
  }

  if (typeof props.chartData[2] !== 'undefined') {
    createFirstChart(2);
  }

  if (typeof props.chartData[3] !== 'undefined') {
    createSecondChat(3);
  }

  const charts = chartIterator.map((i, iterator) => {
      return (<div className="insurance-period-container w100p" key={i}>
        <p className="p__grey fw600 fz16 insurance-period__title">
                            <span>
                                {t('period_of_insurance')} {(i + 1)}
                        </span>
          <br/>
          {periodList[i]}
        </p>
        {(emptyChartIndexes[i] !== false ? (<div className="nodata-period">
          <img src="/assets/img/nodata-period.png" className="nodata-period__img" alt=""/>
          <p className="nodata-period__title">Немає даних для моніторингу,
            ваші періоди страхування почнуться {(typeof periods[i * 2] !== 'undefined' ? periods[i * 2].start : '')}.</p>
        </div>) : (
          <React.Fragment>
            <div className="flex wrap">
              <div className="insurance-period-wrapper">
                {deficitsList[i]}
              </div>
              <div className="insurance-period-wrapper">
                {payoutList[i]}
              </div>
            </div>

            <div className="flex wrap">
              <div className="insurance-period-wrapper">
                <div className="insurance-period__item">{chartsFirstType[i]}</div>
                <div className="chart-legends chart-legends__period wrap flex__start">
                  <div className="chart-legends-item">
                    <div className="chart-legends-item_icon">
                      <img src="/assets/img/legend-icon-6.svg" alt=""/>
                    </div>
                    <div className="chart-legends-item_title">
                      Вміст води в ґрунті в поточному році, %
                    </div>
                  </div>
                  <div className="chart-legends-item">
                    <div className="chart-legends-item_icon">
                      <img src="/assets/img/legend-icon-7.svg" alt=""/>
                    </div>
                    <div className="chart-legends-item_title">
                      Середній вмісту води в ґрунті за 20 років
                    </div>
                  </div>
                  <div className="chart-legends-item">
                    <div className="chart-legends-item_icon">
                      <img src="/assets/img/legend-icon-8.svg" alt=""/>
                    </div>
                    <div className="chart-legends-item_title">
                      дефіцит води в ґрунті
                    </div>
                  </div>
                </div>
              </div>
              <div className="insurance-period-wrapper">
                <div className="insurance-period__item">{chartsSecondType[i]}</div>
                <div className="chart-legends chart-legends__period wrap flex__start">
                  <div className="chart-legends-item">
                    <div className="chart-legends-item_icon">
                      <img src="/assets/img/legend-icon-8.svg" alt=""/>
                    </div>
                    <div className="chart-legends-item_title">
                      Успішний рік, без виплати страховки
                    </div>
                  </div>
                  <div className="chart-legends-item">
                    <div className="chart-legends-item_icon">
                      <img src="/assets/img/legend-icon-9.svg" alt=""/>
                    </div>
                    <div className="chart-legends-item_title">
                      Рік, при якому була б виплата
                    </div>
                  </div>
                  <div className="chart-legends-item">
                    <div className="chart-legends-item_icon">
                      <img src="/assets/img/legend-icon-10.svg" alt=""/>
                    </div>
                    <div className="chart-legends-item_title">
                      Максимальна виплата (стоп лінія)
                    </div>
                  </div>
                  <div className="chart-legends-item">
                    <div className="chart-legends-item_icon">
                      <img src="/assets/img/legend-icon-7.svg" alt=""/>
                    </div>
                    <div className="chart-legends-item_title">
                      Умова виплати (тригер)
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>)
    }
  );

  return (
    <React.Fragment>
      <div className="flex wrap">
        {isAllEmpty ? (<div className='insurance-period-container w100p'>
          <div className="nodata-period">
            <img src="/assets/img/nodata-period.png" className="nodata-period__img" alt=""/>
            <p className="nodata-period__title">Немає даних для моніторингу,
              ваші періоди страхування почнуться {(typeof periods[0] !== 'undefined' ? periods[0].start : '')}.</p>
          </div>
        </div>) : charts}
        <div className="info-block mt60">
          <div className="info-block-text">
            <p>* {t('corteva_calculation_info_block_1')}</p>
            <p className="mt20">** {t('corteva_calculation_info_block_2')}</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default withTranslation()(PackageCalculationResult);


