
export const getMaxValOfArrays = (...numArray) => {
    let max = null;
    // eslint-disable-next-line array-callback-return
    numArray.map(e => {
        let extr = Math.max.apply(null, e);
        if (max === null || extr > max) {
            max = extr;
        }
    })

    return max;
};

export const separateLineByTicks = (x, y, ticksQty) => {
    var lineSpace = y - x;
    var tickSpace = lineSpace / (ticksQty - 1);
    var ticks = [];
    ticks.push(x.toFixed(0));

    for (let i = 1; i <= ticksQty - 2; i++) {
        ticks.push((x + i * tickSpace).toFixed(0));
    }

    ticks.push(y);

    return ticks;
}

export const separateLineByTicksFloat = (list, ticksQty) => {
    var ticks = [];
    const min = Math.min(...list);
    const max = Math.max(...list);
    ticks.push(min);

    const diff = (max - min) / (ticksQty - 2)

    let counter = min;
    for (let i = 1; i <= ticksQty - 2; i++) {
        counter += diff;
        ticks.push(counter);
    }

    ticks.push(max);

    return ticks;
}
