import React from "react";

const InfoBlock = (props) => {
    return (
        <div className="info-block">
            <img src="/assets/img/info-icon.svg" alt=""/>
            <div className="info-block-text">
                <p>
                    {props.infoBlock1}
                    <br/>
                    {props.infoBlock2}
                </p>
            </div>
        </div>
    );
}

export default InfoBlock;
