import React, {useEffect, useState} from 'react';
import CoordinatesPicker from "./coordinates-picker";
import {withTranslation} from "react-i18next";
import CalculatorHeader from "../shared/calculator_header";
import Insurance from "../../services/insurance";
import {insuranceHOC} from "../../utils/insuranceHOC";
import PackageCalculationResult from "./corteva-packages/package-calculation-result";

const insurance = Insurance.getInstance();

export const CortevaProgramOptions = {
    'index': 0,
    'condition': 'dampness',
    'label': 'executive-humidity',
    'cardModifier': "droughts",
    'imgs': ['calc-select-3', 'calc-select-6'],
    'layerIcons': [
        'radio-icon-5.svg',
        'radio-icon-6.svg',
        'radio-icon-7.svg',
        'radio-icon-8.svg',
    ],
    'layers': [
        '0-7'
    ],
    'link': '/calculate-corteva-package/program/corn',
    'availability': true,
    'programs': [
        'chose_package',
        'chose_location',
        'chose_result',
    ]
};

const CortevaPackage = (props) => {
    const {t, i18n, insuranceParams, history} = props;
    const getColor = (trigger) => {
        return trigger ? '#ee9986' : '#27aba6';
    };

    const chartDataOutput = [];
    const emptyChartIndexes = {};
    const chartOptions = [];

    const [insuranceData, setInsuranceData] = useState(insurance.state.value);

    useEffect(() => {
        const subscription = insurance.state.subscribe(e => {
            setInsuranceData(e);
        });

        if (insuranceParams.step > 1) {
            insurance.handleChange(insuranceParams);
        } else if (insuranceData.step === 0) {
            const pathList = history.location.pathname.split('/');
            const crop = pathList[pathList.length - 1];

            insurance.handleChange({'step': 1, 'crop': crop, 'type': 'program', 'lon': null, 'lat': null});
        }

        return () => subscription.unsubscribe();
    }, []);

    const parseDate = (input) => {
        var parts = input.match(/(\d+)/g);
        // note parts[1]-1
        return new Date(parts[2], parts[1]-1, parts[0]);
    }

    const deficits = {};
    const periods = {};
    const payouts = {};

    const fillFirstChart = (sourceStep, step, position) => {
        const chartSource = insuranceData.insuranceSums[sourceStep];
        const dateList = Object.keys(chartSource.waterContentLastYear);
        const ticksH = [];

        emptyChartIndexes[sourceStep] = !(Object.values(chartSource.waterContentLastYear).filter(e => e > 0).length > 0);

        const chartData = [
            [
                t('day'),
                t('avarage_15_year_water_content_in_soil'),
                {role: 'style'},
                {role: 'tooltip'},
                t('water_content_in_2020'),
                {role: 'style'},
                {role: 'tooltip'},
            ]
        ];

        chartOptions[position] = {
            legend: {position: 'none', alignment: 'start'},
            vAxis: {
                viewWindowMode: 'explicit',
                minValue: 0,
                viewWindow: {
                    min: 0
                },
                format: '##,##%'
            },
            hAxis: {
                format: 'd MMM'
            },
            series: {1: { type: 'AreaChart', areaOpacity: 1 }, 2: { type: 'AreaChart', areaOpacity: 1}},
            chart: {},
        };

        for (let dayIndex in dateList) {
            const day = dateList[dayIndex];
            const parsedDate = parseDate(day);
            ticksH.push(parsedDate);
            const replacementsIn2020 = {'%1%': (chartSource.waterContentLastYear[day] * 100).toFixed(2) + '%', '%2%': parsedDate.toLocaleDateString(i18n.language === 'ua' ? 'uk' : i18n.language, {
                    day: 'numeric', month: 'short'
                }).replace(/ /g, ' ')}
            const replacementsLast15Year = {'%1%': (chartSource.avgLast15YearContentInSoil[day] * 100).toFixed(2) + '%', '%2%': parsedDate.toLocaleDateString(i18n.language === 'ua' ? 'uk' : i18n.language, {
                    day: 'numeric', month: 'short'
                }).replace(/ /g, ' ')}

            chartData.push([
                parsedDate,
                chartSource.avgLast15YearContentInSoil[day],
                'stroke-color: #788798; fill-color: #ee9986; fill-opacity: 1',
                t('avarage_15_year_water_content_in_soil_tooltip').replace(/%\w+%/g, function(all) {
                    return replacementsLast15Year[all] || all;
                }),
                chartSource.waterContentLastYear[day],
                'stroke-color: #fec055; fill-color: #ffffff',
                t('water_content_in_2020_tooltip').replace(/%\w+%/g, function(all) {
                    return replacementsIn2020[all] || all;
                }),
            ]);
        }
        chartOptions[position].hAxis.ticks = ticksH;
        chartDataOutput[position] = chartData;
        deficits[position] = chartSource.deficit;
        payouts[position] = chartSource.payout;
        periods[position] = {
            start: chartSource.dateStart,
            end: chartSource.dateEnd
        };
    }

    const fillSecondChart = (sourceStep, step, position) => {
        const chartSource = insuranceData.insuranceSums[sourceStep];
        const dateList = Object.keys(chartSource.deficitByYear);
        const chartData = [
            [
                t('day'),
                t('avarage_15_year_water_content_in_soil'),
                {role: 'style'},
                {role: 'tooltip'},
                t('exit'),
                {role: 'style'},
                {role: 'tooltip'},
                t('above'),
                {role: 'style'},
                {role: 'tooltip'},
            ]
        ];

        chartOptions[position] = {
            legend: {position: 'none', alignment: 'start'},
            vAxis: {
                viewWindowMode: 'explicit',
                minValue: 0,
                viewWindow: {
                    min: 0
                },
                format: '##,##%'
            },
            hAxis: {},
            seriesType: 'bars',
            series: { 1: { type: 'line', areaOpacity: 1}, 2: { type: 'line', areaOpacity: 1}},
            chart: {},
        };

        const conditionOfMaxPayment = t('condition_of_maximal_payout').replace(/%\w+%/g, function(all) {
                return all === '%1%' ? (chartSource.condition_of_maximal_payout_exit * 100).toFixed(2) + '%' : all;
            });
        const conditionOfPayment = t('condition_of_insurance_payment').replace(/%\w+%/g, function(all) {
            return all === '%1%' ? (chartSource.condition_of_insurance_payment * 100).toFixed(2) + '%' : all;
        });

        for (let yearIndex of dateList) {
            const replacementsLast15Year = {
                '%1%': (chartSource.deficitByYear[yearIndex] * 100).toFixed(2) + '%',
                '%2%': yearIndex
            }

            chartData.push([
                yearIndex,
                chartSource.deficitByYear[yearIndex],
                getColor(
                    chartSource.deficitByYear[yearIndex]
                    > chartSource.condition_of_insurance_payment
                ),
                t('water_content_in_2020_tooltip').replace(/%\w+%/g, function(all) {
                    return replacementsLast15Year[all] || all;
                }),
                chartSource.condition_of_maximal_payout_exit,
                'stroke-color: #ee9986; fill-color: #ffffff',
                conditionOfMaxPayment,
                chartSource.condition_of_insurance_payment,
                '#788798',
                conditionOfPayment,
            ]);
        }
        chartData.sort((a, b) => (a[0] < b[0]) ? 1 : (a[0] > b[0]) ? -1 : 0);

        chartDataOutput[position] = chartData;
    }

    if (Object.keys(insuranceData).length > 0 && insuranceData.step === 2 && insuranceData.insuranceSums.length === 2) {
        fillFirstChart(0, 1, 0);
        fillSecondChart(0, 2, 1);
        fillFirstChart(1, 1, 2);
        fillSecondChart(1, 2, 3);
    } else if (Object.keys(insuranceData).length > 0 && insuranceData.step === 2 && insuranceData.insuranceSums.length === 1) {
        fillFirstChart(0, 1, 0);
        fillSecondChart(0, 2, 1);
    }

    let form = ('');

    let headerTitle = '';

    switch (insuranceData.step) {
        default:
        case 1:
            headerTitle = 'mark_field_on_the_map';
            form = (<CoordinatesPicker infoBlock1={t('coordinates-info-block-1')} infoBlock2={t('coordinates-info-block-1')} calculateOnSubmit={true}/>);
            break;
        case 2:
            headerTitle = 'monitoring_of_current_humidity_level';

            form = (<PackageCalculationResult
                chartOptions={chartOptions}
                chartData={chartDataOutput}
                deficits={deficits}
                emptyChartIndexes={emptyChartIndexes}
                periods={periods}
                payouts={payouts}
            />);
            break;
    }

    const HeadTitle = () => insuranceData.step > 0 ? (<CalculatorHeader headerTitle={headerTitle}/>) : '';

    return (
        <React.Fragment>
            <HeadTitle/>
            <div className="calculate-wrapper overflow">
                {form}
            </div>
        </React.Fragment>
    );
}

export default withTranslation()(insuranceHOC()(CortevaPackage));
