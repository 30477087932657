export const xyzToBounds = (x, y, z) => {
    const EXTENT = [-Math.PI * 6378137, Math.PI * 6378137];
    const tileSize = EXTENT[1] * 2 / Math.pow(2, z);
    return [
        EXTENT[0] + x * tileSize,
        EXTENT[1] - (y + 1) * tileSize,
        EXTENT[0] + (x + 1) * tileSize,
        EXTENT[1] - y * tileSize
    ];
}
