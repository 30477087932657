import React, {useEffect, useState} from "react";
import Insurance from "../services/insurance";

const insurance = Insurance.getInstance();

const Preloader = () => {

    const [preloaderStart, setPreloader] = useState(insurance.state.value.preloader);

    useEffect(() => {
        const subscription = insurance.state.subscribe(e => {
            setPreloader(e.preloader);
        });

        return () => subscription.unsubscribe();
    }, []);

    return (
        <div className={'preloader-overlay ' + (preloaderStart ? '' : 'hidden')}>
            <div className="preloader-wrapper">
                <div className="preloader__img">
                    <img src="/assets/img/preloader-img.gif" alt=""/>
                </div>
                <div className="preloader__text">Очікуйте...</div>
            </div>
        </div>
    );
};

export default Preloader;
