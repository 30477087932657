import axios from "axios";
import {BehaviorSubject} from "rxjs";

export default class Insurance {
    static instance = null;
    static _state = {
        lon: null,
        lat: null,
        from: null,
        till: null,
        step: 0,
        layer: null,
        temperature: null,
        insuranceSums: {},
        selectedInsurancePlan: 0,
        insurancePayDown: 0,
        statistics: {},
        isPendingCalculationRequest: false,
        isPendingCheckoutRequest: false,
        type: null,
        preloader: false,
        program: null,
        crop: null,
        oblName: null
    };

    /**
     * @type BehaviorSubject<Insurance._state>
     */
    state = new BehaviorSubject(Insurance._state);

    getStateObservable = () => {
        return this.state.asObservable();
    }

    static getInstance() {
        if (Insurance.instance == null) {
            Insurance.instance = new Insurance();
        }
        return this.instance;
    }

    handleChange = (t, e = null) => {
        if (typeof t === 'object') {
            const obj = Object.assign({}, this.state.value, t);

            if (typeof t['step'] !== 'undefined') {
                if (t['step'] <= 0) {
                    obj['statistics'] = {};
                }
            }

            this.state.next(obj);
            return;
        }

        const obj = Object.assign({}, this.state.value);
        obj[t] = e;
        if (t === 'step' && e <= 0) {
            obj['statistics'] = {};
        }

        this.state.next(obj);
    };

    uploadCalculations = async () => {
        let params = new URLSearchParams(window.location.search);

        this.handleChange({
            'isPendingCalculationRequest': true,
            'link': document.location.href
        });
        const response = await axios.get(process.env.REACT_APP_API_HOST + "/insurance/calculate/" + this.state.value.type, {
            params: {
                layer: this.state.value.layer || params.get('layer'),
                lon: this.state.value.lon,
                lat: this.state.value.lat,
                from: this.state.value.from,
                to: this.state.value.till,
                temperature: this.state.value.temperature,
                crop: this.state.value.crop,
                oblName: this.state.value.oblName
            }
        });

        this.handleChange({
            isPendingCalculationRequest: false,
            insuranceSums: response.data.result,
            statistics: response.data.statistics,
        });
    };

    loadDistricts = async (text) => {
        const response = await axios
            .get(process.env.REACT_APP_API_HOST + "/insurance/search/district/" + text, {});

        return response.data;
    };

    sendRequest = async (params, callback) => {
        params['insuranceData'] = {
            lon: this.state.value.lon,
            lat: this.state.value.lat,
            from: this.state.value.from,
            temperature: this.state.value.temperature,
            till: this.state.value.till,
            selectedInsurancePlan: this.state.value.selectedInsurancePlan,
            type: this.state.value.type,
            link: this.state.value.link
        };

        await axios.post(process.env.REACT_APP_API_HOST + "/insurance/save/form", params);

        callback();
    };
}
