import React, {useState} from 'react';
import {withTranslation} from "react-i18next";

const CortevaProgramApplicantFarm = ({updateProgramApplicantForm, updateProgramApplicantFile, isFormValid}) => {
    const [
        {
            formList,
            file,
            errors
        }, updateFormObj
    ] = useState({
        formList: [{
            id: 1,
            name: '',
            cost: '',
        },
            {
                id: 2,
                name: '',
                cost: '',
            },
            {
                id: 3,
                name: '',
                cost: '',
            }],
        file: null,
        errors: {}
    });

    const updateLastItem = (i, key, value) => {
        const res = {
            formList,
            file,
            errors
        }
        res.formList[i][key] = value;

        if (typeof errors[i] === 'undefined') {
            errors[i] = false;
        }
        if (i === 0) {
            errors[i] = Object.values(res.formList[i]).filter((e, i) => {
                return i > 0 && e.length > 0;
            }).length < 2;
        } else {
            const length = Object.values(res.formList[i]).filter((e, i) => {
                return i > 0 && e.length > 0;
            }).length;
            errors[i] = length > 0 && length < 2;
        }

        if (i === formList.length - 1) {
            res.formList.push({
                id: res.formList.length + 1,
                name: '',
                cost: '',
            });
        }

        updateFormObj(res);
        updateProgramApplicantForm(formList);
        isFormValid(Object.values(errors).filter(e => !!e).length === 0);
    }

    const uploadFile = (e) => {
        updateProgramApplicantFile(e)
    }

    return (
        <div className="program-item mt60">
            <div className="flex flex__start">
                <div className="program-item__step mr15">3</div>
                <div className="flex flex__direction-column">
                    <p className="p__bold">
                        Інформація про придбану продукцію Corteva Agriscience в період з 1 жовтня 2020 по 20 березня 2021 року.
                    </p>
                    <p className="p__grey">з 1 жовтня 2020р по 20 березня 2021р</p>
                </div>
            </div>
            <div className="inputs-title-wrapper flex mt60 mb15 ">
                <div className="input-title fz14 w180px">№ п/п</div>
                <div className="input-title fz14 w700px">Найменування продукції</div>
                <div className="input-title fz14 w270px">Вартість придбання (без ПДВ), грн.</div>
            </div>
            {formList.map((value, key) => (
                <div className={"inputs-wrapper " + (key > 0 ? 'mt10 ' : '') + (errors[key] ? 'error' : '')} key={key}>
                    <div className="custom-input-wrapper flex">
                        <div className="input-wrapper custom-input">
                            <input type="text" disabled="disabled" className="input w180px" placeholder="№ п/п" defaultValue={value.id} onChange={(e) => updateLastItem(key, 'id', e.target.value)} />
                        </div>
                        <div className="input-wrapper custom-input">
                            <input type="text" className="input w700px" placeholder="Найменування продукції" defaultValue={value.name} onChange={(e) =>  updateLastItem(key, 'name', e.target.value)}/>
                        </div>
                    </div>
                    <div className="custom-input-wrapper flex">
                        <div className="input-wrapper custom-input">
                            <input type="text" className="input w270px" placeholder="20 000 грн" defaultValue={value.cost} onChange={(e) =>  updateLastItem(key, 'cost', e.target.value)}/>
                        </div>
                    </div>
                </div>
            ))}
            <div className="attach-file__label mt40">
                <div className="flex flex__start align-items-center">
                    <img src="/assets/img/attach-file.svg" className="mr10" alt="uploadFile"/>
                    <p className="p__medium--green">Прикріпити видаткову накладку на продукцію</p>
                </div>
                <input className="attach-file__input" onChange={e => uploadFile(e.target.files[0])} type="file"/>
            </div>
        </div>
    );
}

export default withTranslation()(CortevaProgramApplicantFarm);
