import React, {useEffect} from "react";
import {Swiper} from "swiper";
import {withTranslation} from "react-i18next";
import {PRICE_MULTIPLIER} from "./covering-picker";
import Insurance from "../../services/insurance";

const insurance = Insurance.getInstance();

const SwiperPricingList = (props) => {
    const {t, isPriceMultiplyRequired, buttons, insuranceSums} = props;

    let swiper = null;
    useEffect(() => {
        if (swiper === null) {
            swiper = new Swiper('.swiper-container', {
                loop: true,
                breakpoints: {
                    320: {
                        slidesPerView: 'auto',
                        spaceBetween: 15,
                        centeredSlides: true,
                    },
                    768: {
                        slidesPerView: 3,
                    },

                }
            }, [buttons]);

            swiper.on('slideChange', function (e) {
                insurance.handleChange('selectedInsurancePlan', e.realIndex);
            });
        }

    }, []);

    return (
        <div className="calculate-coating-container swiper-container">
            <div className="coating-pricing-wrapper swiper-wrapper">
                {buttons.map((column, index) => (
                    <div key={index} className="pricing-item active swiper-slide">
                        <div className="pricing-item-inner">
                            <h4 className="pricing-item-title">{column.text}</h4>
                            <div className="pricing-item-price">
                                <span>{!!insuranceSums[column.value] ? (insuranceSums[column.value]['sum_per_ha'] / (
                                    isPriceMultiplyRequired ? PRICE_MULTIPLIER : 1
                                )).toFixed(2) : ''}</span> <br/>{t('grn/ha')}
                            </div>
                            <div className="pricing-item-desc">{t('cover')} <span>{column.percents}%</span> {t('worst_cases')}
                            </div>
                        </div>
                        <div className="pricing-item-overflow"></div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default withTranslation()(SwiperPricingList);
