import React from 'react';
import {withTranslation} from "react-i18next";
import Insurance from "../../services/insurance";
import {withRouter} from "react-router";

const insurance = Insurance.getInstance();

class Programs extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            step: 0
        }
    }

    componentDidMount() {
        if (typeof this.subscription === 'undefined') {
            this.subscription = insurance.getStateObservable().subscribe(e => {
                setTimeout(this.setState({step: e.step}));
            });
        }
    }

    componentWillUnmount() {
        this.subscription.unsubscribe();
    }

    joinPart = (
        <div className="line">
            <span/>
            <span/>
            <span/>
            <span/>
            <span/>
            <span/>
            <span/>
        </div>
    );

    render() {
        const t = this.props.t;

        this.programsList = this.props.programs;
        this.programsLength = this.props.programs.length - 1;
        const moveToStep = (e) => {
            if (e === 0) {
                if (this.props.history.location.pathname.indexOf('/calculate-insurance') !== -1) {
                    this.props.history.push("/calculate-insurance");
                }
                if (this.props.history.location.pathname.indexOf('/calculate-corteva-package') !== -1) {
                    this.props.history.push("/calculate-corteva-package");
                }
            }

            if (this.state.step > e) {
                insurance.handleChange('step', e);
            }
        }

        const programs = (
            this.programsList.map((e, i) => (
                <React.Fragment key={i}>
                    <div className={"calculate-insurance-step-item pointer " + (i === this.state.step ? 'active' : '')}
                         onClick={() => moveToStep(i)}>
                        <span>{i + 1}</span>
                        <div className="calculate-insurance-step-item_text">
                            {t(e)}
                        </div>
                    </div>
                    {this.programsLength !== i ? this.joinPart : null}
                </React.Fragment>
            ))
        );
        return (
            <div className="calculate-insurance-step">
                {programs}
            </div>
        );
    }
}

export default withTranslation()(withRouter(Programs));
