import {withTranslation} from "react-i18next";
import ReactSEOMetaTags from "react-seo-meta-tags";
import Helmet from "react-helmet";
import {withRouter} from "react-router";
import React from "react";

const MetaTags = (props) => {
    const {t} = props;
    const siteMetadata = {
        title: 'MeteoControl',
        image: process.env.REACT_APP_SITE_URL + '/assets/img/logo.svg',
        imgSiteUrl: process.env.REACT_APP_SITE_URL + '/assets/img/logo.svg',
        siteUrl: process.env.REACT_APP_SITE_URL,
        site: {
            siteName: 'MeteoControl'
        },
        author: {
            name: 'Ihor',
            image: ''
        },
        breadcrumb: [
            { name: 'MeteoControl', url: process.env.REACT_APP_SITE_URL },
            { name: 'MeteoControl - ' + t('seo_description_2'), url: process.env.REACT_APP_SITE_URL + '/calculate-insurance' },
            { name: 'MeteoControl - ' + t('seo_description_3'), url: process.env.REACT_APP_SITE_URL + '/calculate-insurance/drought' },
        ],
        organization: {
            name: 'MeteoControl',
            legalName: 'MeteoControl',
            logo: process.env.REACT_APP_SITE_URL + '/assets/img/logo.svg',
            url: process.env.REACT_APP_SITE_URL
        }
    }

    switch (document.location.pathname) {
        default :
        case '/':
            siteMetadata.title = 'MeteoControl'
            siteMetadata.description = 'MeteoControl - ' + t('seo_description_1');
            break;
        case '/calculate-insurance':
            siteMetadata.title = t('seo_description_2')
            siteMetadata.description = 'MeteoControl - ' + t('seo_description_2');
            break;
        case '/calculate-insurance/drought':
            siteMetadata.title = [t('seo_description_2'), t('seo_description_3')].join(': ');
            siteMetadata.description = 'MeteoControl - ' + t('seo_description_3');
            break;
    }

    return (
        <ReactSEOMetaTags
            render={(el) => <Helmet>{el}</Helmet>}
            website={siteMetadata}
        />
    );
}

export default withTranslation()(withRouter(MetaTags));
