import React, {useEffect, useState} from 'react';
import InputMask from 'react-input-mask';
import {withTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import Insurance from "../../services/insurance";

const insurance = Insurance.getInstance();

const Checkout = (props) => {
    const {t} = props;
    const [{
        formTouched,
        phoneValid,
        firstName,
        phone,
        companyName
    }, updateFormObj] = useState({
        formTouched: false,
        phoneValid: false,
        firstName: '',
        phone: '',
        companyName: ''
    });
    const isFormFilled = [firstName, phone, companyName].findIndex(e => e === '' || e === null) === -1;
    const isConfirmApplied = isFormFilled && phoneValid;
    const history = useHistory();

    const [insuranceData, setInsuranceData] = useState(insurance.state.value);

    useEffect(() => {
        const subscription = insurance.state.subscribe(e => {
            setInsuranceData(e);
        });

        return () => subscription.unsubscribe();
    }, []);

    if (insuranceData.step === 0) {
        history.push("/calculate-insurance");
    }
    const updateForm = (t, e) => {
        const res = {
            formTouched: true,
            phoneValid,
            firstName,
            phone,
            companyName
        };
        switch (t) {
            case 'phone':
                res['phoneValid'] = e.target.value.length > 0 && e.target.value.indexOf('_') === -1;
                res[t] = e.target.value;
                break;
            case 'firstName':
            case 'companyName':
                res[t] = e.target.value;
                break;
            default:
                break;
        }
        updateFormObj(res);
    };
    const sendForm = (e) => {
        e.preventDefault();

        if (!isConfirmApplied) {
            return;
        }

        insurance.sendRequest({
            firstName,
            phone,
            companyName
        }, () => {
            history.push('/success');
        });
    };

    return (
        <div className="calculate-wrapper">
          <div className="calculate-insurance-contact-form">
            <form>
              <div className="contact-form-wrapper">
                <div className="contact-form-field-title">{t('name')}</div>
                <div className="contact-form-field">
                  <input type="text" required defaultValue={firstName}
                         onChange={(e) => updateForm('firstName', e)} id="defaultRegisterFormFirstName"
                         className={(firstName || (!firstName && !formTouched) ? "" : 'is-invalid')}
                         placeholder={t('name')}/>
                  <img src="/assets/img/contact-icon-1.svg" alt=""/>
                </div>
                <div className="contact-form-field-title">{t('phone')}</div>
                <div className="contact-form-field">
                  <InputMask mask="+380 (99) 999-99-99"
                             defaultValue={phone}
                             placeholder={t('phone')}
                             onChange={(e) => updateForm('phone', e)}
                             id="defaultRegisterFormPhone"
                             className={"form-control " + (phoneValid || (!phoneValid && !formTouched) ? "" : 'is-invalid')}
                  />
                  <img src="/assets/img/contact-icon-2.svg" alt=""/>
                </div>
                <div className="contact-form-field-title">{t('company_name')}</div>
                <div className="contact-form-field">
                  <input type="text" defaultValue={companyName}
                         onChange={(e) => updateForm('companyName', e)} id="defaultRegisterFormCompanyName"
                         className={"form-control " + (companyName || (!companyName && !formTouched) ? "" : 'is-invalid')}
                         placeholder={t('company_name')}/>
                  <img src="/assets/img/contact-icon-3.svg" alt=""/>
                </div>
              </div>
              <button className={"contact-form-btn " + (isConfirmApplied ? '' : 'disabled')}
                      onClick={(e) => sendForm(e)} type="submit">{t('call')}</button>
            </form>
            <div className="info-block">
              <img src="/assets/img/info-icon.svg" alt=""/>
              <p className="info-block-text">{t("checkout-tooltip")}</p>
            </div>
          </div>
        </div>
    )
}

export default withTranslation()(Checkout);
