import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router";
import InputMask from "react-input-mask";
import CortevaProgramApplicantFarm from "./corteva-program-applicant-farm";
import CortevaProgramCrops from "./corteva-program-crops";
import axios from "axios";
import {Link} from "react-router-dom";
import Insurance from "../../services/insurance";

const insurance = Insurance.getInstance();

const CortevaProgram = () => {
    let [{
        companyName,
        edrpou,
        postAddress,
        phone,
        email,
        fax,
        supplier,
        externalForm,
        externalFormFile,
        externalFormCrops,
        isModalOpen,
        isFormSent,
        inputErrors,
        isCropsValid,
        isApplicantValid,
        isMainFormValid,
        isScrolled
    }, updateFormObj] = useState({
        companyName: '',
        edrpou: '',
        postAddress: '',
        phone: '',
        email: '',
        fax: '',
        supplier: '',
        externalForm: [],
        externalFormFile: undefined,
        externalFormCrops: [],
        isModalOpen: false,
        isFormSent: false,
        inputErrors: {
            companyName: {
                touched: false,
                error: false
            },
            edrpou: {
                touched: false,
                error: false
            },
            postAddress: {
                touched: false,
                error: false
            },
            phone: {
                touched: false,
                error: false
            },
            email: {
                touched: false,
                error: false
            },
            supplier: {
                touched: false,
                error: false
            },
        },
        isCropsValid: false,
        isApplicantValid: false,
        isMainFormValid: false,
        isScrolled: false
    });

    useEffect(() => {
        if (!isScrolled) {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
            updateForm('isScrolled', true);
        }
    }, []);

    const updateForm = (t, e) => {
        const res = {
            companyName,
            edrpou,
            postAddress,
            phone,
            email,
            fax,
            supplier,
            externalForm,
            externalFormFile,
            externalFormCrops,
            isModalOpen,
            inputErrors,
            isCropsValid,
            isApplicantValid,
            isMainFormValid,
            isScrolled
        };
        switch (t) {
            case 'isApplicantValid':
            case 'isCropsValid':
                res[t] = e;
                break;
            case 'isFormSent':
            case 'isScrolled':
                res.isFormSent = e;
                break;
            case 'isModalOpen':
                res[t] = e;
                res.isModalOpen = true;
                break;
            default:
                if (typeof inputErrors[t] !== "undefined") {
                    inputErrors[t].touched = true;
                    inputErrors[t].error = e.target.value.toString().length === 0;
                }

                res[t] = e.target.value;
                break;
        }
        res.isMainFormValid = Object.values(inputErrors).filter(e => !e.error && e.touched).length === 6;
        isMainFormValid = res.isMainFormValid;

        updateFormObj(res);
    };

    const updateProgramApplicantForm = (e) => {
        externalForm = e;
    };

    const updateProgramCrops = (e) => {
        externalFormCrops = e;
    };

    const updateProgramApplicantFile = (e) => {
        externalFormFile = e;
    };

    const isApplicantFormValid = (e) => {
        updateForm('isApplicantValid', e)
    }
    const isProgramCropsValid = (e) => {
        updateForm('isCropsValid', e)
    }

    const sendCortevaRequest = async () => {
        if (isFormSent === true) {
            return;
        }

        updateForm('isFormSent', true);
        const data = {
            purchasedProducts: JSON.stringify(externalForm),
            sowing: JSON.stringify(externalFormCrops),
            file: externalFormFile,
            name: companyName,
            address: postAddress,
            telFaxEmail: [ phone, fax, email ].filter(e => !!e).join(', '),
            distributorName: supplier,
            edrpou,
        }

        var bodyFormData = new FormData();

        for (const dataKey in data) {
            bodyFormData.append(dataKey, data[dataKey]);
        }

        insurance.handleChange('preloader', true);

        await axios({
            method: 'post',
            url: process.env.REACT_APP_API_HOST + "/insurance/request/corteva",
            data: bodyFormData,
            headers: {'Content-Type': 'multipart/form-data' }
        })
            .then(function (response) {
            })
            .catch(function (response) {
                //handle error
            });

        updateForm('isModalOpen', true);
        insurance.handleChange('preloader', false);
    }

    return (
        <section className="program">
            <div className="container">
                <div className="program__title">
                    <h2 className="h2__bold">
                        Термін подачі заявки завершено
                    </h2>
                    {/*<h2 className="h2__bold">*/}
                    {/*    Заява на страхування витрат <br/>*/}
                    {/*    на продукцію Corteva Agriscience*/}
                    {/*</h2>*/}
                </div>
                {/*<div className="program-item">*/}
                {/*    <div className="flex flex__start align-items-center">*/}
                {/*        <div className="program-item__step mr15">1</div>*/}
                {/*        <p className="p__bold">*/}
                {/*            Відомості про господарство-заявника*/}
                {/*        </p>*/}
                {/*    </div>*/}
                {/*    <div className="input-flex-wrapper flex">*/}
                {/*        <div className="input-wrapper w770px">*/}
                {/*            <div className="input-title fz14 mb15">*Повна назва</div>*/}
                {/*            <input type="text" defaultValue={companyName} onChange={e => updateForm('companyName', e)}*/}
                {/*                   className={"input w100p " + (inputErrors.companyName.error ? 'error' : '')} placeholder="*Введіть назву"/>*/}
                {/*            {(inputErrors.companyName.error ? (<p className="error-notification text-red fz12 mt5">Заповніть, будь ласка, поле</p>) : '')}*/}
                {/*        </div>*/}
                {/*        <div className="input-wrapper w370px">*/}
                {/*            <div className="input-title fz14 mb15">*Код ЄДРПОУ</div>*/}
                {/*            <InputMask mask="99999999"*/}
                {/*                       defaultValue={edrpou}*/}
                {/*                       maskChar=" "*/}
                {/*                       placeholder="*Код ЄДРПОУ"*/}
                {/*                       onChange={(e) => updateForm('edrpou', e)}*/}
                {/*                       id="defaultRegisterFormEDRPOU"*/}
                {/*                       className={"input w100p " + (inputErrors.edrpou.error ? 'error' : '')}*/}
                {/*            />*/}
                {/*            {(inputErrors.edrpou.error ? (<p className="error-notification text-red fz12 mt5">Заповніть, будь ласка, поле</p>) : '')}*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="input-flex-wrapper flex">*/}
                {/*        <div className="input-wrapper w100p">*/}
                {/*            <div className="input-title fz14 mb15">*Поштова (фактична) адреса</div>*/}
                {/*            <input type="text" defaultValue={postAddress} onChange={e => updateForm('postAddress', e)} className={"input w100p " + (inputErrors.postAddress.error ? 'error' : '')} placeholder="*Введіть назву"/>*/}
                {/*            {(inputErrors.postAddress.error ? (<p className="error-notification text-red fz12 mt5">Заповніть, будь ласка, поле</p>) : '')}*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="input-flex-wrapper flex">*/}
                {/*        <div className="input-wrapper w370px">*/}
                {/*            <div className="input-title fz14 mb15">*Телефон</div>*/}
                {/*            <InputMask mask="+380 (99) 999-99-99"*/}
                {/*                       defaultValue={phone}*/}
                {/*                       placeholder="* +380 __ ___ __ __"*/}
                {/*                       onChange={(e) => updateForm('phone', e)}*/}
                {/*                       id="defaultRegisterFormPhone"*/}
                {/*                       className={"input w100p " + (inputErrors.phone.error ? 'error' : '')}*/}
                {/*            />*/}
                {/*            {(inputErrors.phone.error ? (<p className="error-notification text-red fz12 mt5">Заповніть, будь ласка, поле</p>) : '')}*/}
                {/*        </div>*/}
                {/*        <div className="input-wrapper w370px">*/}
                {/*            <div className="input-title fz14 mb15">*E-mail</div>*/}
                {/*            <input type="text" defaultValue={email} onChange={e => updateForm('email', e)} className={"input w100p " + (inputErrors.email.error ? 'error' : '')} placeholder="*E-mail"/>*/}
                {/*            {(inputErrors.email.error ? (<p className="error-notification text-red fz12 mt5">Заповніть, будь ласка, поле</p>) : '')}*/}
                {/*        </div>*/}
                {/*        <div className="input-wrapper w370px">*/}
                {/*            <div className="input-title fz14 mb15">Факс</div>*/}
                {/*            <InputMask mask="+380 (99) 999-99-99"*/}
                {/*                       defaultValue={fax}*/}
                {/*                       placeholder="* +380 __ ___ __ __"*/}
                {/*                       onChange={(e) => updateForm('fax', e)}*/}
                {/*                       id="defaultRegisterFormFax"*/}
                {/*                       className="input w100p"*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="program-item mt60">*/}
                {/*    <div className="flex flex__start align-items-center">*/}
                {/*        <div className="program-item__step mr15">2</div>*/}
                {/*        <p className="p__bold">*/}
                {/*            Постачальник (дистриб’ютор) продукії Corteva Agriscience*/}
                {/*        </p>*/}
                {/*    </div>*/}
                {/*    <div className="input-flex-wrapper flex mt25">*/}
                {/*        <div className="input-wrapper w100p">*/}
                {/*            <div className="input-title fz14 mb15">*Повна назва</div>*/}
                {/*            <input type="text" defaultValue={supplier} onChange={e => updateForm('supplier', e)} className={"input w100p " + (inputErrors.supplier.error ? 'error' : '')} placeholder="*Введіть назву"/>*/}
                {/*            {(inputErrors.supplier.error ? (<p className="error-notification text-red fz12 mt5">Заповніть, будь ласка, поле</p>) : '')}*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<CortevaProgramApplicantFarm updateProgramApplicantForm={updateProgramApplicantForm} isFormValid={isApplicantFormValid} updateProgramApplicantFile={updateProgramApplicantFile}/>*/}

                {/*<CortevaProgramCrops updateProgramCrops={updateProgramCrops} isFormValid={isProgramCropsValid}/>*/}
                {/*<div className="program-item relative">*/}
                {/*    <div className="flex align-center">*/}
                {/*        <button className={"btn btn-main btn-default w370px mt100 " + (isFormSent || (!isCropsValid || !isApplicantValid) || !isMainFormValid ? 'disabled' : '')} onClick={e => sendCortevaRequest()}>Подати заявку</button>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>

            <div className={"modal-overlay " + (isModalOpen ? 'show' : 'd-none')}>
                <div className="modal-wrapper">
                    <button className="modal__close-btn">
                        <Link to="/">
                            <img src="/assets/img/close-btn.svg" alt="closeBtn"/>
                        </Link>
                    </button>
                    <div className="order-card">
                        <div className="flex align-center">
                            <div className="flex flex__direction-column order-card__text">
                                <h2 className="h2__small--green">Вітаємо!</h2>
                                <h1 className="h1__medium fw600 mt25">
                                    Ваша заявка
                                    успішно надіслана
                                </h1>
                                <p className="p__grey mt25">
                                    З вами зовсім скоро зв'яжуться наші менеджери
                                    для уточнення деталей
                                </p>
                            </div>
                            <div className="order-card__img">
                                <img src="/assets/img/mail-img.png" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default withTranslation()(withRouter(CortevaProgram));
