import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import Insurance from "../../services/insurance";

const insurance = Insurance.getInstance();

const ButtonBack = (props) => {
    const {t} = props;
    const history = useHistory();

    const [insuranceData, setInsuranceData] = useState(insurance.state.value);

    useEffect(() => {
        const subscription = insurance.state.subscribe(e => {
            setInsuranceData(e);
        });

        return () => subscription.unsubscribe();
    }, []);

    const moveToStep = (e) => {
        if (e === 0) {
            if (history.location.pathname.indexOf('/calculate-insurance') !== -1) {
                history.push("/calculate-insurance");
            }
            if (history.location.pathname.indexOf('/calculate-corteva-package') !== -1) {
                history.push("/calculate-corteva-package");
            }
        }
        if (history.location.pathname === '/calculate-insurance/checkout') {
            history.push("/calculate-insurance");
        }

        if (insuranceData.step > e) {
            insurance.handleChange('step', e);
        }
    }

    return (
        <div className={!!props.classValue ? props.classValue : 'back-btn'} style={props.styling}
             onClick={() => moveToStep(insuranceData.step - 1)}>
            <img src={!!props.img ? props.img : '/assets/img/back-arrow.svg'} alt=""/>
            <span>{t('back')}</span>
        </div>
    );
}

export default withTranslation()(ButtonBack);
