import {withTranslation} from "react-i18next";
import React from 'react';

const CalculatorHeader = (props) => {
    const {t} = props

    return (
        <div className="section-title">
            <h2>{t(props.headerTitle)}</h2>
        </div>
    );
}

export default withTranslation()(CalculatorHeader);
