
export const formatThousandNumbers = (val) => {
    if (parseInt(val) === 0) {
        return '0,00';
    }
    var trunc = Math.trunc(val);
    var fract = ((val - trunc) * 100).toFixed(0);
    let thousands = Math.trunc(val / 1000);
    let part = trunc - thousands * 1000;
    switch (true) {
        case part <= 100 && part > 9:
            part = '0' + part.toString();
            break;
        case part < 10:
            part = '00' + part.toString();
            break;
        case part === 0:
            part = '000';
            break;
        default:
            part = part.toString();
            break;
    }
    if (trunc >= 1000) {
        return thousands.toString() + ' ' + part+ ',' + fract.toString();
    }
    if (trunc < 1000 && trunc > 0) {
        return trunc + ',' + fract;
    }

    return val;
}
