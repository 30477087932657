import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Chart} from "react-google-charts";
import Moment from 'react-moment';
import 'moment/min/moment-with-locales'
import 'moment/locale/uk';
import 'moment/locale/en-gb';
import {withTranslation} from "react-i18next";
import {formatThousandNumbers} from "../../utils/formater";
import {checkIsPriceMultiplyRequired} from "../../utils/Conditions";
import MobileButtonBack from "../shared/mobile-button-back";
import Insurance from "../../services/insurance";
import PricingList from "./pricing-list";

const insurance = Insurance.getInstance();

export const PRICE_MULTIPLIER = 30;

function CoveringPicker(props) {
    const {t, i18n} = props;
    const [insuranceData, setInsuranceData] = useState(insurance.state.value);

    useEffect(() => {
        const subscription = insurance.state.subscribe(e => {
            setInsuranceData(e);
        });
        insurance.uploadCalculations();

        return () => subscription.unsubscribe();
    }, []);
    const locale = i18n.language === 'en' ? 'en' : 'uk';
    const from = insuranceData.from;
    const till = insuranceData.till;

    const isPriceMultiplyRequired = checkIsPriceMultiplyRequired(i18n.language);

    let listGroupPage = ('');

    if (typeof insuranceData.insuranceSums[insuranceData.selectedInsurancePlan] !== 'undefined') {
        listGroupPage = (
            <div className="coating-about">
                <div className="coating-about-item">
                    <img src="/assets/img/pricing-icon-1.svg" alt=""/>
                    <div className="coating-about-item-text">{t('trigger')} <br/> <span> {props.triggerValue}</span>
                    </div>
                </div>
                <div className="line">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div className="coating-about-item">
                    <img src="/assets/img/pricing-icon-2.svg" alt=""/>
                    {props.overPayment}
                </div>
                <div className="line">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div className="coating-about-item">
                    <img src="/assets/img/pricing-icon-3.svg" alt=""/>
                    <div className="coating-about-item-text">{t('condition_maximum_payment')} <br/>
                        <span>{props.conditionOfMaxPayment}</span></div>
                </div>
                <div className="line">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div className="coating-about-item">
                    <img src="/assets/img/pricing-icon-4.svg" alt=""/>
                    <div className="coating-about-item-text">{t('maximum_payment_amount')} <br/>
                        <span>{(props.maxInsuranceSum / (isPriceMultiplyRequired ? PRICE_MULTIPLIER : 1)).toFixed(2)}</span> {t('grn/ha')}
                    </div>
                </div>
            </div>
        );
    }

    let chart = ('');
    let statisticTable = ('');
    if (insuranceData.selectedInsurancePlan >= 0 && Object.keys(insuranceData.statistics).length > 0) {
        chart = (
            <div style={{zIndex: 999}}>
                <Chart
                    width={'100%'}
                    height={'400px'}
                    chartType="ComboChart"
                    loader={<div>{t('loading_chart')}</div>}
                    data={props.chartData}
                    options={props.chartOptions}
                />
            </div>
        );

        statisticTable = (
            <table>
                <thead>
                <tr>
                    {
                        props.tableData.columns.map((e, q) => (
                            <th key={q} className="col-caption">{e.label}</th>
                        ))
                    }
                </tr>
                </thead>
                <tbody>
                {
                    props.tableData.columns[0].rows.map((e, q) => (
                            <tr key={q}>
                                <td className="table-year">{e}</td>
                                <td className="table-payments">
                                    {formatThousandNumbers(props.tableData.columns[1].rows[q])}
                                </td>
                            </tr>
                        )
                    )
                }
                </tbody>
            </table>
        );
    }

    return (
        <div className="calculate-coating">
            <div className="calculate-coating-wrapper">
                <PricingList
                    isPriceMultiplyRequired={isPriceMultiplyRequired}
                    buttons={props.buttons}
                    selectedInsurancePlan={insuranceData.selectedInsurancePlan}
                    insuranceSums={insuranceData.insuranceSums}/>
                <div className="pricing-charts-wrapper">
                    <div className="pricing-chart">
                        <div className="pricing-chart-title">
                            <h4>
                                {props.chartTitle} {t('from')} <Moment locale={locale} format="D MMM."
                                                                       parse="YYYY-MM-DD"
                                                                       date={from}/> {t('till')} <Moment
                                locale={locale}
                                format="D MMM." parse="YYYY-MM-DD" date={till}/>.
                            </h4>
                        </div>
                        {chart}
                        <div className="chart-legends">
                            {[0,1,2,3,4].map((i) => (
                                <div key={i} className="chart-legends-item">
                                    <div className="chart-legends-item_icon">
                                        <img src={"/assets/img/legend-icon-" + (i + 1) + ".svg"} alt=""/>
                                    </div>
                                    <div className="chart-legends-item_title">
                                        {t('covering-legend-' + (i + 1))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="pricing-table">
                        <div className="pricing-table-title">
                            <h4>{t('amount_payment_policy')}</h4>
                        </div>
                        <div className="table-wrapper">
                            {statisticTable}
                        </div>
                    </div>
                </div>
                {listGroupPage}
                <div className="coating-btn">
                    <Link to='/calculate-insurance/checkout' className="btn-default"
                          disabled={insuranceData.isPendingCalculationRequest}>
                        {t('order_a_policy')}
                    </Link>
                </div>
            </div>
            <MobileButtonBack/>
            <div className="info-block">
                <img src="/assets/img/info-icon.svg" alt=""/>
                <div className="info-block-text">
                    <p>{t('copernicus-1')} (<a
                        href="https://www.copernicus.eu/en">https://www.copernicus.eu/en</a>),<br/>
                        {t('copernicus-2')} (<a
                            href="https://ec.europa.eu/info/index_en">https://ec.europa.eu/info/index_en</a>),
                        {t('copernicus-3')} <br/>
                        {t('copernicus-4')}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(CoveringPicker);
