import {DroughtLayerOptions} from "../components/calculate-insurance/drought";
import {TemperatureLayerOptions} from "../components/calculate-insurance/temperature";
import {ExecutiveHumidityLayerOptions} from "../components/calculate-insurance/executive-humidity";
import {
    BarleyPackageLayerOptions,
    CornPackageLayerOptions,
    SunflowerPackageLayerOptions, WheatPackageLayerOptions
} from "../components/calculate-insurance/corteva-packages/corn";

export const BasePrograms = [
    'chose_program',
    'chose_layer',
    'chose_period_of_insurance',
    'chose_location',
    'chose_cover',
];
export const BaseProgramButtons = [
    DroughtLayerOptions,
    TemperatureLayerOptions,
    ExecutiveHumidityLayerOptions,
];

export const CortevaPackagePrograms = [
    'chose_package',
    'chose_location',
    'chose_result',
];
export const CortevaPackageButtons = [
    CornPackageLayerOptions,
    SunflowerPackageLayerOptions,
    WheatPackageLayerOptions,
    BarleyPackageLayerOptions
];
