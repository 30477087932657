import React from 'react';
import {withTranslation} from "react-i18next";
import InfoBlock from "./info-block";
import MobileButtonBack from "../shared/mobile-button-back";
import Insurance from "../../services/insurance";

const insurance = Insurance.getInstance();

const GroundLayer = (props) => {
    const {t} = props;
    let insuranceData = insurance.state.value;

    return (
        <React.Fragment>
            <div className="program-inner">
                <div className={"program-card " + props.column.condition + " clicked"}>
                    <div className="program-card-img">
                        {
                            props.column.imgs.map((e, i) => (
                                <img key={e + i} src={"/assets/img/" + e + ".svg"} alt=""/>
                            ))
                        }
                    </div>
                    <div className="program-card-title">{t(props.column.label)}</div>
                </div>
                <div className="select-radio-wrapper">
                    {
                        props.column.layers.map((e, i) => (
                            <div key={i} className="radiobutton-item">
                                <div className="radiobutton-info" onClick={() => {
                                    insurance.handleChange('layer', e);
                                    insurance.handleChange('step', insuranceData.step + 1);
                                }}>
                                    <label>
                                        <input className="radiobutton" type="radio" name="radio"/>
                                        <span className="fake-radiobutton"/>
                                    </label>
                                    <div className="radiobutton-text">{e} {t('sm')}</div>
                                </div>
                                <img src={"/assets/img/" + (props.column.layerIcons[i])} alt=""/>
                            </div>
                        ))
                    }
                </div>
            </div>
            <MobileButtonBack/>
            <InfoBlock infoBlock1={props.infoBlock1} infoBlock2={props.infoBlock2}/>
        </React.Fragment>
    );
}

export default withTranslation()(GroundLayer);
