import React from "react";
import {withTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";

const SuccessOrder = (props) => {
    const {t} = props;
    const history = useHistory();
    setTimeout(() => {
        history.push("/");
    }, 5000);

    return (
        <div className="calculate-insurance">
            <div className="container">
                <div className="calculate-done">
                    <div className="section-title">
                        <h2>{t('success-text')}</h2>
                    </div>
                    <div className="done-wrapper">
                        <div className="done-icon">
                            <img src="/assets/img/done-icon.svg" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(SuccessOrder);
