import React, {useEffect, useState} from 'react';
import {Link, Route, Switch} from "react-router-dom";
import {withRouter} from "react-router";
import Drought from "./drought";
import Temperature from "./temperature";
import Checkout from "./checkout";
import ExecutiveHumidity from "./executive-humidity";
import Programs from "./programs";
import {withTranslation} from "react-i18next";
import ButtonBack from "../shared/button-back";
import CalculatorHeader from "../shared/calculator_header";
import InfoBlock from "./info-block";
import Insurance from "../../services/insurance";
import CortevaPackage from "./corteva-package";

const insurance = Insurance.getInstance();

const CalculateInsurance = (({history, ...props}) => {
    const {t, location} = props;

    let programs = props.programs;

    const [insuranceData, setInsuranceData] = useState(insurance.state.value);
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        if (!isScrolled) {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
            setIsScrolled(true);
        }
        const subscription = insurance.state.subscribe(e => {
            setInsuranceData(e);
        });

        return () => subscription.unsubscribe();
    }, []);

    if (history.location.pathname === '/calculate-insurance') {
        if (insuranceData.step >= 1) {
            insurance.handleChange({
                'step': 0,
                'lon': null,
                'lat': null,
                'from': null,
                'till': null,
                'layer': null,
                'insuranceSums': {},
                'temperature': null,
                'type': null,
            });
        }
    }

    if (history.location.pathname === '/calculate-corteva-package') {
        if (insuranceData.step >= 1) {
            insurance.handleChange({
                'step': 0,
                'insuranceSums': {},
                'crop': null,
                'oblName': null,
                'lon': null,
                'lat': null,
                'from': null,
                'till': null,
                'layer': null,
                'temperature': null,
                'type': null,
            });
        }
    }

    const isCheckoutPage = history.location.pathname === '/calculate-insurance/checkout';
    for (let i = 0; i < props.buttonsList.length; i++) {
        if (props.buttonsList[i].link === history.location.pathname) {
            programs = props.buttonsList[i].programs;
            break;
        }
    }
    let programList = isCheckoutPage ? (<Programs programs={[]}/>) : (
        <Programs programs={programs}/>
    );

    const insuranceWay = (
        <div className="calculate-wrapper">
            <div className="select-program">
                <div className="select-program-inner">
                    {props.buttonsList.map((column, index) => (
                        <Link to={column.link} key={index.toString()} disabled={!column.availability}
                              className={'program-card ' + column.cardModifier}>
                            <div className="program-card-img">
                                {
                                    column.imgs.map((e, i) => (
                                        <img key={e + i} src={"/assets/img/" + e + ".svg"} alt=""/>
                                    ))
                                }
                            </div>
                            <div className="program-card-title">{t(column.label)}</div>
                            <p className="program-card-desc">
                                {t(column.desc)}
                            </p>
                        </Link>
                    ))}
                </div>
            </div>
            <InfoBlock infoBlock1={t('calculate-insurance-info-block-1')} infoBlock2={t('calculate-insurance-info-block-2')}/>
        </div>
    );

    const HeadTitle = () =>  {
        switch (true) {
            case location.pathname === '/calculate-corteva-package':
                return (<CalculatorHeader headerTitle={'choose_package_of_bought_products'}/>);
            case insuranceData.step === 0:
                return (<CalculatorHeader headerTitle={'what_harms_your_harvest'}/>);
            case location.pathname === '/calculate-insurance/checkout':
                return (<CalculatorHeader headerTitle={'your_contact_details'}/>);
            default: return '';
        }
    };

    const BtnBack = () => insuranceData.step > 0 ? (
        <ButtonBack styling={{marginTop: isCheckoutPage ? '15px' : ''}}/> ) : ('');

    return (
        <div className="calculate-insurance">
            <div className="container">
                {programList}
                <BtnBack/>
                <HeadTitle/>
                <Switch>
                        <Route exact path="/calculate-insurance">
                            {insuranceWay}
                        </Route>
                        <Route path="/calculate-insurance/drought" component={Drought}/>
                        <Route path="/calculate-insurance/excessive_humidity" component={ExecutiveHumidity}/>
                        <Route path="/calculate-insurance/temperature" component={Temperature}/>
                        <Route path="/calculate-insurance/checkout" component={Checkout}/>
                </Switch>
                <Switch>
                        <Route exact path="/calculate-corteva-package">
                            {insuranceWay}
                        </Route>
                        <Route path="/calculate-corteva-package/program/corn" component={CortevaPackage}/>
                        <Route path="/calculate-corteva-package/program/sunflower" component={CortevaPackage}/>
                        <Route path="/calculate-corteva-package/program/wheat" component={CortevaPackage}/>
                        <Route path="/calculate-corteva-package/program/barley" component={CortevaPackage}/>
                </Switch>
            </div>
        </div>
    )
})

export default withTranslation()(withRouter(CalculateInsurance));
