import React, {useState} from 'react';
import {withTranslation} from "react-i18next";

const CortevaProgramCrops = ({updateProgramCrops, isFormValid}) => {
    const [
        {
            formList,
            errors,
        }, updateFormObj
    ] = useState({
        formList: [{
            id: 1,
            region: '',
            district: '',
            city: '',
            crop: '',
            square: '',
        },
            {
                id: 2,
                region: '',
                district: '',
                city: '',
                crop: '',
                square: '',
            },
            {
                id: 3,
                region: '',
                district: '',
                city: '',
                crop: '',
                square: '',
            }],
        file: undefined,
        errors: {}
    });


    const updateLastItem = (i, key, value) => {
        const res = {
            formList,
            errors
        }
        res.formList[i][key] = value;

        if (typeof errors[i] === 'undefined') {
            errors[i] = false;
        }
        if (i === 0) {
            errors[i] = Object.values(res.formList[i]).filter((e, i) => {
                return i > 0 && e.length > 0;
            }).length < 5;
        } else {
            const length = Object.values(res.formList[i]).filter((e, i) => {
                return i > 0 && e.length > 0;
            }).length;
            errors[i] = length > 0 && length < 5;
        }

        if (i === formList.length - 1) {
            res.formList.push({
                id: formList.length + 1,
                region: '',
                district: '',
                city: '',
                crop: '',
                square: '',
            });
        }
        updateFormObj(res);
        updateProgramCrops(formList);
        isFormValid(Object.values(errors).filter(e => !!e).length === 0);
    }

    return (
        <div className="program-item relative mt60">
            <div className="flex flex__start align-items-center">
                <div className="program-item__step mr15">4</div>
                <p className="p__bold">Посіви, де планується застосуватися продукція Corteva Agriscience</p>
            </div>
            <div className="inputs-title-wrapper flex mt60 mb15">
                <div className="input-title fz14 w130px">№ п/п</div>
                <div className="input-title fz14 w200px">Область (регіон)</div>
                <div className="input-title fz14 w230px">Район</div>
                <div className="input-title fz14 w230px">Населений пункт</div>
                <div className="input-title fz14 w200px">Культура</div>
                <div className="input-title fz14 w170px">Площа, Га</div>
            </div>
            {formList.map((formItem, key) => (
                <div className={"inputs-wrapper " + (key > 0 ? 'mt15 ' : '') + (errors[key] ? 'error' : '')} key={key}>
                    <div className="input-wrapper">
                        <input disabled="disabled" type="text" className="input input--w100p w130px" placeholder="№ п/п" defaultValue={formItem.id}
                               onChange={(e) => updateLastItem(key, 'id', e.target.value)}/>
                    </div>
                    <div className="input-wrapper">
                        <input type="text" className="input input--w100p w200px" placeholder="Область" defaultValue={formItem.region}
                               onChange={(e) => updateLastItem(key, 'region', e.target.value)}/>
                    </div>
                    <div className="input-wrapper">
                        <input type="text" className="input input--w100p w230px" placeholder="Район" defaultValue={formItem.district}
                               onChange={(e) => updateLastItem(key, 'district', e.target.value)}/>
                    </div>
                    <div className="input-wrapper">
                        <input type="text" className="input input--w100p w230px" placeholder="Населений пункт"
                               defaultValue={formItem.city}
                               onChange={(e) => updateLastItem(key, 'city', e.target.value)}/>
                    </div>
                    <div className="input-wrapper">
                        <input type="text" className="input input--w100p w200px" placeholder="Культура" defaultValue={formItem.crop}
                               onChange={(e) => updateLastItem(key, 'crop', e.target.value)}/>
                    </div>
                    <div className="input-wrapper">
                        <input type="text" className="input input--w100p w170px" placeholder="Площа, Га" defaultValue={formItem.square}
                               onChange={(e) => updateLastItem(key, 'square', e.target.value)}/>
                    </div>
                </div>
            ))}
            <img src="/assets/img/elem-1.svg" className="program-page__elem-1" alt=""/>
        </div>
    );
}

export default withTranslation()(CortevaProgramCrops);
